<template>
  <div class="wrap-container">
    <div class="header-container">
      <Header></Header>
      <!-- <fixed-header></fixed-header> -->
    </div>
    <div class="main-container">
      <router-view />
    </div>
    <div class="footer-container">
      <fixed-footer></fixed-footer>
    </div>
    <!-- 右侧图钉 nav -->
    <!-- <thumbtack-nav></thumbtack-nav> -->
    <right-nav v-if="showRightNav"></right-nav>
  </div>
</template>

<script>
// import ThumbtackNav from '../components/thumbtackNav.vue';
import Header from "./components/header/Header";
import { FixedHeader, FixedFooter } from "./components/index";
import RightNav from "./rightNav.vue";

export default {
  name: "Home",
  components: {
    Header,
    // FixedHeader,
    FixedFooter,
    // ThumbtackNav,
    RightNav,
  },
  data() {
    return {
      showRightNav:true,
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        if(to.fullPath=='/policyLibrary'){
          this.showRightNav = false;
        }else {
          this.showRightNav = true;
        }
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.wrap-container {
  // width: 100vw;
  width: 100%;
  height: 100%;
  position: relative;
  // min-width: 1200px;
  .header-container {
    // background: rgba(0, 0, 0, 0) linear-gradient(rgb(53, 129, 190), rgb(84, 149, 202)) repeat scroll 0% 0%;
    // background: url('../assets/images/bananer_top.png') center / 100% 100% no-repeat;
  }
  .main-container {
    background-color: #ffffff;
    min-height: calc(100vh - #{$headerHeight} - #{$footerHeight});
    margin-bottom: -15px;
    //padding-bottom: 30px;
    // background: rgba(0, 0, 0, 0) linear-gradient(rgb(51, 204, 209), rgb(84, 149, 202)) repeat scroll 0% 0%;
    // min-height: 600px;
  }
  .footer-container {
    // margin-top: 50px;
    // background-color:#fafafa;
    color: #45484b;
  }
}
.home-container {
  position: relative;
}
.home-title {
  height: 2.5rem;
  width: 100%;
  background-color: #e9e9e9;
  .home-title_1 {
    height: 100%;
    width: 40%;
    margin-left: 15%;
    .home-title_2 {
      height: 100%;
      width: 20%;
      float: left;
      text-align: center;
      font-size: 17px;
      padding: 8px;
      cursor: pointer;
    }
    .home-title_2:hover {
      height: 100%;
      width: 20%;
      float: left;
      text-align: center;
      font-size: 17px;
      padding: 8px;
      cursor: pointer;
      color: #ffffff;
      background-color: #0067b6;
    }
    .home-title_2s {
      height: 100%;
      width: 20%;
      float: left;
      text-align: center;
      font-size: 17px;
      padding: 8px;
      cursor: pointer;
      color: #ffffff;
      background-color: #0067b6;
    }
    .home-title_3 {
      color: #000000;
    }
    .home-title_3:hover {
      color: #ffffff;
    }
  }
}
</style>
