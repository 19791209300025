<template>
    <div :class="$route.path == '/home' ? 'bk-0 header-content' : 'header-content'">
        <div
            class="def-container"
            :class="
        'declare'.indexOf($route.name) == -1 ? 'main-height' : 'declare-height'
      "
        >
            <div class="flex-column-between hb-bt">
                <div class="header-title-container">
                    <div class="header-title-content flex-row-between">
                        <div
                            class="header-left flex-row-between"
                            @click="toHome"
                        >
                            <el-image
                                class="header-logo pointer"
                                :src="$route.path == '/home' ? headerLogo : headerLogo1"
                                lazy
                            ></el-image>
                        </div>
                        <div
                            class="header-right pointer flex-row"
                            v-if="'declare'.indexOf($route.name) == -1"
                        >
                            <a
                                style="margin-right: 10px"
                                :style="$route.path == '/home' ? 'color: #fff' : 'color: #333'"
                                v-for="(item, index) in linksList"
                                :key="index"
                                :href="item.url"
                                target="_blank"
                            >
                                {{ item.name }}
                            </a>
                        </div>
                        <div
                            class="header-right pointer"
                            @click="gotoHome"
                            v-if="'declare'.indexOf($route.name) !== -1"
                        >
                            返回首页
                        </div>
                    </div>
                </div>
                <nav
                    class="flex-row-between header-nav"
                    v-if="'declare'.indexOf($route.name) == -1"
                >
                    <ul class="nav-wrap pointer">
                        <head-bar-item
                            v-for="(el, index) in navList"
                            :key="index"
                            :path="el.value"
                            :index="index"
                            :currentNavIndex="currentNavIndex"
                        >
                            <template v-slot:item-text>{{ el.name }}</template>
                        </head-bar-item>
                    </ul>

                    <!-- 用户信息登陆后 -->
                    <el-popover
                        ref="popover1"
                        placement="bottom"
                        width="180"
                        trigger="hover"
                    >
                        <div class="flex-column-between loginInfo">
                            <div
                                class="login-cell pointer"
                                v-for="(item, index) in loginList"
                                :key="index"
                                @click="gotoItem(item.path)"
                            >
                                <i
                                    class="cell-icon"
                                    :class="item.icon"
                                ></i>
                                <div class="login-name">{{ item.name }}</div>
                            </div>
                        </div>
                    </el-popover>
                    <!-- 当前修改的前端代码 -->
                    <el-popover
                        ref="popoverMessage"
                        placement="bottom"
                        width="300"
                        trigger="hover"
                    >
                        <div class="flex-column-between messageInfo pointer">
                            <ul class="nav-wrap">
                                <li
                                    :class="[
                    'message-titleList',
                    msTitleCurrentNav === msIndex
                      ? 'blue-color blue-bottom'
                      : '',
                  ]"
                                    v-for="(el, msIndex) in msTitleList"
                                    :key="msIndex"
                                    @click="changemsNav(msIndex)"
                                >
                                    {{ el.name }}（{{ getLength(msIndex) }}）
                                </li>
                            </ul>
                            <!-- 线条 -->
                            <div class="message-list"></div>
                            <!-- 通知列表 -->

                            <div
                                class="message-cell pointer"
                                v-for="(item, index) in messageList"
                                :key="index"
                                @click="gotoMessage(item)"
                            >
                                <div class="message-name">{{ item.title }}</div>
                                <div class="message-time">{{ item.createTime }}</div>
                            </div>
                            <!-- 暂无数据 -->
                            <div v-show="messageList.length == 0">
                                <el-image
                                    class="img"
                                    :src="srcMessage"
                                    fit="cover"
                                ></el-image>
                            </div>
                            <!--查看更富多  -->
                            <div
                                class="message-showMore pointer"
                                @click="gotoMyMessage"
                            >
                                查看更多
                            </div>
                        </div>
                    </el-popover>

                    <div
                        v-show="userInfo !== null"
                        :class="
              $route.path == '/home'
                ? 'login-register flex-row-between '
                : 'login-register1 flex-row-between '
            "
                    >
                        <div
                            class="login-icon flex-row pointer"
                            @click="toWork"
                            v-popover:popover1
                        >
                            <i class="el-icon-user-solid"></i><span class="max-one-line ui-name">{{
                userInfo ? userInfo.name || userInfo.nickName : ""
              }}</span>
                        </div>
                        <span class="u-sperator">|</span>
                        <div
                            class="register-cion flex-row pointer el-icon-message-solid"
                            @click="toWork"
                            v-popover:popoverMessage
                        ></div>
                    </div>
                    <!-- 未登录显示 -->
                    <div
                        v-if="userInfo == null"
                        :class="
              $route.path == '/home'
                ? 'login-register flex-row-between'
                : 'login-register1 flex-row-between'
            "
                    >
                        <div
                            class="login-icon flex-row pointer"
                            @click="toLogin"
                        >
                            <i class="el-icon-user"></i>登录
                        </div>
                        <span class="u-sperator">|</span>
                        <div
                            class="register-cion flex-row pointer"
                            @click="toRegister"
                        >
                            <i class="el-icon-edit-outline"></i>注册
                        </div>
                    </div>
                </nav>
            </div>
            <el-dialog
                :visible.sync="dialogTableVisible"
                width="30%"
            >
                <login-block></login-block>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoginBlock from "@/views/login/index.vue";
import HeadBarItem from "./headBarItem";
export default {
    name: "headerC",
    components: {
        LoginBlock,
        HeadBarItem,
    },
    computed: {
        ...mapGetters({
            hNavAction: "getHNavAction",
            // 把 `this.userInfo` 映射为 `this.$store.getters.getUserInfo`
            userInfo: "getUserInfo",
        }),
    },
    watch: {
        hNavAction: {
            immediate: true,
            handler(val) {
                console.log("hNavAction：", val);
                for (let i = 0; i < this.navList.length; i++) {
                    if (this.navList[i].value === val) {
                        this.currentNavIndex = i;
                    }
                }
            },
        },
    },
    data() {
        return {
            currentNav: 0,
            // 顶部友情链接地址
            linksList: [
                {
                    name: '国家政务服务平台',
                    url: 'http://gjzwfw.www.gov.cn/index.html',
                },
                {
                    name: '广东省政务服务网',
                    url: 'https://www.gdzwfw.gov.cn/',
                },
                {
                    name: '广东省中小企业融资平台',
                    url: 'http://zxr.gdjr.gd.gov.cn/portal/static/cofinance/index.html',
                },
            ],
            // 通知公告订阅的下表
            msTitleCurrentNav: 0,
            currentNavIndex: 0,
            headerLogo: require("@/assets/images/header-logo0.png"),
            headerLogo1: require("@/assets/images/header-logo1.png"),
            /* 控制 非首页 情况下的样式 */
            showStyle: 0,
            /* 登入弹窗 */
            dialogTableVisible: false,
            // 通知栏图片地址
            srcMessage: require("@/assets/404_images/no_data.png"),
            // nav
            // activeIndex: this.hNavAction,
            /* 登录后 hover 弹出的框 */
            loginList: [
                {
                    name: "工作台",
                    icon: "el-icon-user",
                    path: "/workplace/index",
                },
                {
                    name: "账户管理",
                    icon: "el-icon-setting",
                    path: "/workplace/setting",
                },
                {
                    name: "退出",
                    icon: "el-icon-switch-button",
                    path: "/logout",
                },
            ],
            // nav list
            navList: [
                {
                    name: "首页",
                    value: "/home",
                },
                {
                    name: "扶持政策",
                    value: "/policySupport",
                },
                // {
                //   name: "项目申报",
                //   value: "/projectList",
                // },
                // {
                //   name: "项目申报",
                //   value: "/findList",
                // },
                {
                    name: '政策申报',
                    value: '/policyProgram',
                },
                {
                    name: "政策匹配",
                    value: "/policyMatch",
                },
                // {
                //   name: "通知公告",
                //   value: "/noticeNotice",
                // },

                // {
                //   name: "政策解读",
                //   value: "/policyInterpretation",
                // },
                {
                    name: "信息公示",
                    value: "/notice",
                },
                {
                    name: "使用帮助",
                    value: "/useHelp",
                },
            ],
            // header-right
            items: [
                {
                    name: "手机版",
                    path: "",
                },
                {
                    name: "小程序",
                    path: "",
                },
            ],
            // 通知栏小铃铛的消息列表
            tongzhiList: [

            ],
            // 公告
            gonggaoList: [

            ],
            // 订阅
            dingyueList: [

            ],
            // 用来传递订阅公告通知的数组 messageList
            messageList: [

            ],
            params: {
                pageNum: 1,
                pageSize: 3,
            },
            gonggaoTotal: 0,
            tongzhiTotal: 0,
            dingyueTotal: 0,

            // 通知 公告 订阅
            msTitleList: [
                {
                    name: "通知",
                    path: "",
                },
                {
                    name: "公告",
                    path: "",
                },
                {
                    name: "订阅",
                    path: "",
                },
            ],
        };
    },
    mounted() {
        if (this.userInfo != null) {
            this.loadTongzhi();
            this.loadGongao();
            this.loadDingyue();
        }
    },
    created() {

    },
    methods: {
        changeNav(index) {
            let path = this.navList[index].value;
            this.$store.commit("updateHNavAction", path);
            this.$router.replace({ path: path });
        },
        /* 改变通知栏 */
        changemsNav(index) {
            this.msTitleCurrentNav = index;
            if (index === 0) {
                this.messageList = this.tongzhiList;
            } else if (index === 1) {
                this.messageList = this.gonggaoList;
            } else {
                this.messageList = this.dingyueList;
            }

            let path = this.msTitleList[index].path;
            this.$router.replace({ path: path });
        },
        /* 登入后转跳 */
        headerLogin() {
            console.log("header的登入");
            this.$router.push({ path: "/login" });
        },
        /* 退出 */
        gotoItem(el) {
            console.log("el:", el);
            if (el == "/logout") {
                this.$confirm("是否退出登录？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$store.dispatch("logout", {}).then(() => {
                            this.$message.success('登出成功')
                            this.$router.push('/home')
                        })
                    })
                    .catch(() => { })
            } else {
                this.$router.push({ path: el });
            }
        },
        /**
         * 注册
         */
        toRegister() {
            this.$router.push({
                path: "/register",
            });
        },
        /* 登入按钮 */
        toLogin() {
            this.$router.push({ path: "/login" });
        },
        /* touser转跳到个人工作台 */
        toWork() {
            this.$router.push({ path: '/workplace/index' })
        },
        /* nav */
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
            /* 如果 key 不是 / 就改变header样式 */
            this.$store.commit("updateHNavAction", key);
            this.changeShow();
            // console.log("store", this.hNavAction)
        },
        /* 更改显示状态 */
        changeShow() {
            if (this.hNavAction !== "/home") {
                this.showStyle = 1;
            } else this.showStyle = 0;
        },
        /* 转跳到首页 */
        toHome() {
            // 在首页和申报页面点击图片时不回到首页
            if (this.$route.path !== "/home" && this.$route.path !== "/declare") {
                this.$router.push({name:"policyLibrary",params:{titleType:1}});
                this.$store.commit("updateHNavAction", "/");
            }
        },
        gotoHome() {
            this.$confirm(
                "退出将会丢失部分录入信息，您可以选择暂存功能保存当前录入信息。是否确定退出当前事项办理？",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    this.$router.go(-1);
                })
                .catch(() => { });
        },
        getLength(index) {
            if (index === 0) {
                return this.tongzhiTotal
            } else if (index === 1) {
                return this.gonggaoTotal;
            } else {
                return this.dingyueTotal;
            }
        },
        // 前往我的信息中心
        gotoMyMessage() {
            this.$router.push({ path: "/workplace/message" });
        },
        // 加载我的通知栏的三个总数 只执行一次
        loadTongzhi() {
            let that = this
            this.$httpApi.get('/dev-api/message/list?type=1', this.params).then((res) => {
                if (res.code == 200) {
                    that.tongzhiList = res.rows
                    that.tongzhiTotal = res.total
                    that.messageList = that.tongzhiList
                }
            })
                .catch((err) => {

                });

            console.log(this.messageList)
        },
        loadDingyue() {
            let that = this
            this.$httpApi.get('/dev-api/message/list?type=3', this.params).then((res) => {
                if (res.code == 200) {
                    that.dingyueList = res.rows
                    that.dingyueTotal = res.total
                }
            })
                .catch((err) => {

                });
        },
        loadGongao() {
            let that = this
            this.$httpApi.get('/dev-api/message/list?type=2', this.params).then((res) => {
                if (res.code == 200) {
                    that.gonggaoList = res.rows
                    that.gonggaoTotal = res.total
                }
            })
                .catch((err) => {

                });
        },
        gotoMessage() {
            let that = this;
            console.log("点击,转跳");
            this.$router.push({
                path: "/workplace/message/detail/4057c346ac7c4a3d9c06dd920ec51af4?type=0"
            });
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
.ui-name {
    max-width: 100px;
}
.u-sperator {
    margin: 0 10px;
}
/* 选中颜色 */
.color-selcet {
    color: $color-primary;
}
/* 切换样式 */
.bk-0 {
    background: url("../../../assets/images/bananer_top.png") center / 100% 100%
        no-repeat;
}
.c3 {
    color: #333;
}
/* 在申报页面的时候取消导航栏 */
.main-height {
    height: 147px;
}
.declare-height {
    margin-bottom: 20px;
}
/* 主要结构 */
.header-content {
    position: relative;
    /* 登入弹窗结构 */
    .el-dialog__wrapper {
        width: 100%;
        height: 100%;
        .el-dialog {
            width: 30% !important;
        }
    }
    /*  */
    .hb-bt {
        // border-bottom: 1px solid rgba(#fff, 0.3);
        height: 100%;
    }
    .header-title-container {
        width: 100%;
        .header-title-content {
            padding-top: 24px;
            // height: 85px;
            color: #fff;
            // background: red;
            .header-left {
                .header-logo {
                    width: 500px;
                    height: 52px;
                }
                .header-position {
                    margin-left: 24px;
                    padding-left: 24px;
                    .header-position-title {
                        max-width: 90px;
                        font-weight: 600;
                        font-size: 22px;
                    }
                    .header-position-tabs {
                        width: 90px;
                        height: 20px;
                        background: rgb(98, 231, 142);
                    }
                }
            }
            .header-right {
                color: #333;
                .header-right-item {
                    padding: 0 15px;
                }
            }
        }
    }
    .header-nav {
        width: 100%;
        // width: 1200px;
        height: 54px;
        margin: 0 auto;
        align-items: flex-end !important;
        .el-menu {
            height: 100% !important;
            border-bottom: none;
        }
        .el-menu-item,
        .el-menu-item1 {
            height: 100% !important;
            // background-color: #fff !important;
            //  width: 50%;
            &,
            li {
                // box-sizing: border-box;
                font-size: 18px;
                padding: 0 10px;
                margin: 0 10px;
            }
        }
        .el-menu-item:hover {
            background-color: rgba(24, 126, 250, 0) !important;
            // border-bottom: 4px solid $color-fff !important;
            border-bottom-width: 4px;
        }
        .el-menu-item.is-active {
            // border-bottom: 4px solid $color-fff !important;
            border-bottom-width: 4px;
        }
        .login-register {
            // width: 180px;
            height: 30px;
            border-radius: 15px;
            background: rgba(255, 255, 255, 0.1);
            color: #fff;
            padding: 0 20px;
            margin-bottom: 10px;
            .login-icon,
            .register-cion {
                // padding: 0 15px;
                img {
                    margin-right: 8px;
                    width: 14px;
                    height: 14px;
                    // line-height: 16px;
                }
            }
        }
        .login-register1 {
            // width: 180px;
            height: 30px;
            border-radius: 15px;
            background: rgba(0, 0, 0, 0.1);
            color: #333;
            padding: 0 20px;
            margin-bottom: 10px;
            .login-icon,
            .register-cion {
                // padding: 0 15px;
                img {
                    margin-right: 8px;
                    width: 14px;
                    height: 14px;
                    display: block;
                    // line-height: 16px;
                }
            }
        }
        i {
            margin-right: 8px;
            width: 14px;
            height: 14px;
            display: block;
            // line-height: 16px;
        }
    }
}
.loginInfo {
    width: 100%;
    padding: 0 15px;
    .login-cell {
        width: 100%;
        padding: 8px 0;
        border-bottom: 1px solid #f1f1f1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        font-weight: 600;
        .cell-icon {
            width: 30px;
        }
        .login-name {
            flex: 1;
        }
    }
    .login-cell:hover {
        color: $color-primary1;
    }
    .login-cell:last-child {
        border-bottom: none;
    }
}
.login-register {
    transition: all 0.3s ease;
    &:hover {
        box-shadow: 0 0 20px 4px rgba($color-fff, 0.3);
    }
}

.login-icon,
.register-cion {
    &:hover {
        // color: $color-primary1;
        font-weight: bold;
    }
}

.nav-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

    .nav-messgaeTitle {
        font-size: 12px;
        font-weight: 500;
    }
    .message-titleList {
        padding: 8px 10px;
        font-size: 12px;
        font-weight: 800;
    }
}

.messageInfo {
    //xingliangxiede
    width: 100%;
    padding: 10 1px;
    .message-list {
        height: 20px;
    }
    .message-cell {
        width: 100%;
        padding: 8px 0;
        border-bottom: 1px solid #f1f1f1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;

        .message-name {
            font-size: 10px;
            flex: 1;
        }
        .message-time {
            text-align: right;
            font-size: 8px;
            flex: 1;
        }
    }
    .message-showMore {
        width: 100%;
        text-align: center;
        font-size: 10px;
        margin-top: 20px;
    }
    .message-cell:hover {
        color: $color-primary1;
    }
    .message-showMore:hover {
        color: $color-primary1;
    }
    .message-cell:last-child {
        border-bottom: none;
    }
}
.img {
    margin-left: 9%;
    width: 100%;
}
.blue-color {
    color: $color-primary1;
}
</style>
