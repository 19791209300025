import Vue from 'vue';
import VueRouter from 'vue-router';
import cookie from '../utils/cookie'
import store from '@/store/index'
/* Layout */
import Layout from '@/layout'
import {
  LOGIN_URL,
  LOGIN_URL_BACK
} from '@/store/mutation-types'
// 解决push到重复URL报错的问题 Error: Avoided redundant navigation to current location:
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [{
    path: '/',
    name: 'Layout',
    redirect: '/home',
    component: Layout,
    // path: '/',
    // name: 'home',
    // redirect: '/home',
    // component: Home,
    children: [{
        path: 'home',
        name: "home",
        component: () => import('../views/home/index.vue'),
        redirect: '/homePage',
        children: [{
          path: '/policyLibrary',
          name: 'policyLibrary',
          component: () =>
            import('../views/policyLibrary/policyLibrary.vue'),
        }, ]
      },
      {
        path: '/homePage',
        name: 'homePage',
        component: () => import('../views/homePage/homePage.vue')
      },
      {
        path: '/applyGuide',
        name: 'applyGuide',
        component: () => import('../views/homePage/applyGuide.vue')
      },
      {
        path: '/homeContent',
        name: 'homeContent',
        component: () => import('../views/home/homeContent.vue')
      },
      {
        path: '/policyProgram',
        name: 'policyProgram',
        component: () => import('../views/findList/PolicyProgram')
      },
      {
        path: '/policyEnterprises',
        name: 'policyEnterprises',
        component: () => import('../views/findList/policyEnterprises')
      },
      {
        path: '/appeal',
        name: 'appeal',
        component: () => import('../views/appeal/appeal')
      },
      {
        path: '/qysq',
        name: 'qysq',
        component: () =>
          import('@/views/qiyesuqiu/qysq.vue'),
      },
      {
        path: '/policyAnalysis',
        name: 'policyAnalysis',
        component: () =>
          import('../views/policyAnalysis/index.vue'),
      },
      {
        path: '/enterpriseLibrary',
        name: 'enterpriseLibrary',
        component: () =>
          import('../views/policyLibrary/enterpriseLibrary.vue'),
      },
      {
        path: '/policyService',
        name: 'policyService',
        component: () =>
          import('../views/policyLibrary/policyService.vue'),
      },
      {
        path: '/policy-advisory',
        name: 'policyAdvisory',
        component: () =>
          import('../views/policy-advisory/index'),
      },
      {
        path: '/showPolicyDetail',
        name: 'showPolicyDetail',
        component: () =>
          import('../views/policyLibrary/showPolicyDetail.vue'),
      },
      {
        path: '/oneThingGuide',
        name: 'oneThingGuide',
        component: () =>
          import('../views/findList/oneThingGuide.vue'),
      },
      {
        path: '/showPolicy',
        name: 'showPolicy',
        component: () =>
          import('../views/policyLibrary/showPolicy.vue'),
      },
      {
        path: '/qysqDetail',
        name: 'qysqDetail',
        component: () =>
          import('@/views/qiyesuqiu/qysqDetail.vue'),
      },
      {
        path: 'home_early',
        component: () => import('../views/home/index_early.vue')
      },
      {
        path: '/projectList',
        name: 'projectList',
        component: () => import('../views/declare/projectList.vue')
      },
      {
        path: '/findList',
        name: '项目申报',
        component: () => import('../views/findList/index.vue')
      },
      {
        path: '/policyDeclaration',
        name: 'policyDeclaration',
        component: () => import('../views/policyDeclaration/Index'),
        meta: {
          title: '政策项目申报',
          needLogin: true,
        },
      },
      {
        path: '/policyMatch',
        name: 'policyMatch',
        component: () => import('../views/findList/policyMatch.vue'),
        meta: {
          title: '政策匹配',
        },
      },
      {
        path: '/noticeNotice',
        name: '通知公告',
        component: () =>
          import('../views/noticeNotice/index.vue')
      },
      {
        path: '/noticeNotice/detail/:id',
        name: 'NoticeNoticeDetail',
        component: () =>
          import('@/views/noticeNotice/detail.vue'),
      },
      {
        path: '/policySupport',
        name: 'PolicySupport',
        component: () =>
          import('../views/policySupport/index.vue')
      },
      {
        path: '/policySupport/detail/:id',
        name: 'PolicySupportDeatil',
        component: () =>
          import('../views/policySupport/detail.vue')
      },
      {
        path: '/policy',
        name: 'policy',
        component: () =>
          import('../views/policyLibrary/policymeta.vue'),
      },
      {
        path: '/policyDetails',
        // 政策详情
        name: 'policyDetails',
        component: () =>
          import('../views/policyLibrary/policyDetails.vue'),
      },
      {
        path: '/policyMeta',
        name: 'policyMeta',
        component: () =>
          import('../views/policyMeta/policyMeta.vue'),
      },
      {
        path: '/policySubscribe',
        name: 'policySubscribe',
        component: () =>
          import('../views/policySubscribe/policySubscribe.vue'),
      },
      {
        path: '/policyInterpretation',
        name: 'PolicyInterpretation',
        component: () =>
          import('../views/policyInterpretation/index.vue'),
      },
      {
        path: '/policyInterpretation/detail',
        name: 'PolicyInterpretationDetail',
        component: () =>
          import('@/views/policyInterpretation/detail.vue'),
      },
      {
        path: '/enjoyWithoutApply',
        name: 'enjoyWithoutApply',
        component: () =>
          import('@/views/enjoyWithoutApply/index.vue'),
      },
      {
        path: '/useHelp',
        name: 'UseHelp',
        component: () =>
          import('../views/useHelp/index.vue')
      },
      {
        path: '/guide',
        name: 'guide',
        component: () =>
          import('../views/guide/guide.vue')
      },
      {
        path: '/guideSuqiu',
        name: 'guideSuqiu',
        component: () =>
          import('../views/findList/guideSuqiu.vue')
      },
      {
        path: '/guideSuqiu_early',
        name: 'policyGuide',
        component: () => import('../views/findList/PolicyGuide.vue'),
        meta: {
          title: '政策办事指南',
        },
      },
      {
        path: '/guideSuqiuStreamline',
        name: 'guideSuqiuStreamline',
        component: () =>
          import('../views/findList/guideSuqiuJJ.vue')
      },
      {
        path: '/guideShenpi',
        name: 'guideShenpi',
        component: () =>
          import('../views/findList/guideShenpi.vue')
      },
      {
        path: '/guideShenpiStreamline',
        name: 'guideShenpiStreamline',
        component: () =>
          import('../views/findList/guideShenpiJJ.vue')
      },
      {
        path: '/declare',
        name: 'declare',
        meta: {
          needLogin: true
        },
        component: () =>
          import('../views/declare/declare.vue')
      },
      {
        path: '/declareShenpi',
        name: 'declareShenpi',
        meta: {
          needLogin: true
        },
        component: () =>
          import('../views/declare/declareShenpi.vue')
      },
      {
        path: '/itemDeclare',
        name: 'itemDeclare',
        meta: {
          needLogin: true
        },
        component: () =>
          import('../views/declare/declareShenpi/index.vue')
      },
      {
        path: '/specialColumn/:id', // 首页专栏-详细页
        name: 'specialColumn',
        component: () =>
          import('../views/specialColumn/index.vue')
      },
      {
        path: '/specialGangaoyueColumn/:id', // 首页专栏-详细页
        name: 'specialGangaoyueColumn',
        component: () =>
          import('../views/specialColumn/gangaoyueIndex.vue')
      },
      {
        path: '/test',
        name: '测试页面',
        component: () =>
          import('../views/declare/test.vue')
      },
      {
        path: '/notice',
        name: '信息公示',
        component: () =>
          import('../views/notice/index.vue')
      },
      {
        path: '/notice/detail/:id',
        component: () =>
          import('../views/notice/detail.vue')
      },
      // 政策解读详情
      {
        path: '/notice/policyExplainPage',
        component: () =>
          import('../views/notice/policyExplainPage.vue')
      },
      {
        path: '/notice/policyNotice/:id',
        component: () =>
          import('../views/notice/policyNotice.vue')
      },
      {
        path: '/register',
        name: '/register',
        component: () =>
          import('@/views/register/index'),
        //   hidden: false
      },

      {
        path: '/searchList',
        name: 'searchList',
        component: () =>
          import('@/views/home/searchList'),
      },
      {
        path: '/searchCompany',
        name: 'searchCompany',
        component: () =>
          import('@/views/home/searchCompany'),
      },
      {
        path: '/deptInfo/:id',
        name: '部门信息',
        component: () =>
          import('@/views/deptInfo/index.vue')
      },
      {
        path: 'policy_config_billboard',
        name: 'policyConfigBillboard',
        component: () => import('@/views/policyConfigBillboard/PolicyConfigBillboard'),
        meta: {
          title: '政策配置看板',
        },
      },
      {
        path: 'policy_config_billboard/matter_presence',
        name: 'policyMatterPresence',
        component: () => import(
          '@/views/policyConfigBillboard/policyMatterPresence/PolicyMatterPresence'),
        meta: {
          title: '政策事项进驻情况',
        },
      },
      {
        path: '/launchSuqiu',
        name: 'launchSuqiu',
        component: () =>
          import('@/views/qiyesuqiu/launchSuqiu.vue'),
      },
      {
        path: '/policyMatchReport',
        name: 'policyMatchReport',
        component: () =>
          import('@/views/workplace/policyMatchReport.vue'),
      },
      {
        path: '/policyMatchDetails',
        name: 'policyMatchDetails',
        component: () =>
          import('@/views/workplace/policyMatchDetails.vue'),
      },
      {
        path: '/serviceGuide',
        name: 'serviceGuide',
        component: () =>
          import('@/views/findList/serviceGuide.vue'),
      },
      {
        path: '/workplace',
        name: 'workplace',
        component: () => import('../views/workplace/index.vue'),
        redirect: '/workplace/index',
        children: [{
            path: 'index',
            meta: {
              needLogin: true
            },
            component: () => import('../views/workplace/components/workplace.vue')
          },
          {
            path: '/workplace/message',
            name: '消息中心',
            meta: {
              needLogin: true
            },
            component: () => import('../views/workplace/components/message/index.vue')
          },
          {
            path: '/workplace/policy',
            name: '政策列表',
            meta: {
              needLogin: true
            },
            component: () => import('../views/workplace/components/policy/Index')
          },
          // 我的诉求
          {
            path: '/workplace/myAppeal',
            name: 'myAppeal',
            meta: {
              needLogin: true
            },
            component: () => import('../views/workplace/components/myAppeal/Index')
          },
          {
            path: '/workplace/collection',
            name: 'collection',
            meta: {
              needLogin: true
            },
            component: () => import('../views/workplace/components/collection/index.vue')
          },
          {
            path: '/workplace/setting',
            name: '设置',
            meta: {
              needLogin: true
            },
            component: () => import('../views/workplace/components/setting/index.vue')
          },
          {
            path: '/workplace/file',
            name: '我的材料',
            meta: {
              needLogin: true
            },
            component: () => import('../views/workplace/components/file/index.vue')
          },
          {
            path: '/workplace/subscribe',
            name: '我的订阅',
            meta: {
              needLogin: true
            },
            component: () => import('../views/workplace/components/subscribe/index.vue')
          },
          {
            path: '/workplace/appeal',
            name: '我的诉求',
            meta: {
              needLogin: true
            },
            component: () => import('../views/workplace/components/appeal/index.vue')
          },
          {
            path: '/workplace/plan',
            name: '计划录入',
            meta: {
              needLogin: true
            },
            component: () => import('../views/workplace/components/plan/index.vue')
          },
          {
            path: '/workplace/message/detail/:id', // 首页专栏-详细页
            component: () =>
              import('../views/workplace/components/message/detail.vue')
          },
          {
            path: '/workplace/backlog',
            name: '我的事项',
            meta: {
              needLogin: true
            },
            component: () =>
              import('../views/workplace/components/kuaiji/backlog.vue')
          },
          // {
          //   path: '/workplace/hasdone',
          //   name: '已办事件',
          //   meta: {
          //     needLogin: true
          //   },
          //   component: () =>
          //     import('../views/workplace/components/kuaiji/hasdone.vue')
          // },
        ]
      },
    ]
  },
  {
    path: '/previewPdf',
    component: () => import('@/components/preview/previewPdf'),
    name: 'previewPdf',
    meta: {
      title: 'pdf',
      icon: 'el-icon-s-home'
    }
  },
  {
    path: '/404',
    component: () =>
      import('@/views/errorPage/404')
  },
  {
    path: '/401',
    component: () =>
      import('@/views/errorPage/401'),
    hidden: true
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
  },
  {
    path: '/findPwd',
    name: 'FindPwd',
    component: () =>
      import('@/views/login/findPwd.vue'),
    //   hidden: false
  },
  {
    path: '/compliance',
    redirect: '/compliance/index',
    component: Layout,
    children: [{
      path: 'index',
      component: () => import('@/views/compliance/Index.vue'),
      meta: {
        title: '企业合规'
      },
    }, ],
  },


  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  routes,
  // 跳转路由后默认滚动到顶部
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
});

/*
 * beforeEach:从一个页面跳转到另外一个页面时触发
 * to:要跳转的页面
 * from:从哪个页面出来
 * next:决定是否通过
 */
const whiteList = ['/', 'register', 'registerResult', '/homeContent']
router.beforeEach((to, from, next) => {
  store.commit("updateHNavAction", to.path);
  let path = to.path
  switch (path) {
    // case "/policyLibrary":
    // case "/searchList":
    //   sessionStorage.setItem("titleType", 1);
    //   break;
    // case "/policy":
    //   sessionStorage.setItem("titleType", 2);
    //   break;
    // case "/policyMatchReport":
    //   sessionStorage.setItem("titleType", 3);
    //   break;
    // case "/showPolicy":
    //   sessionStorage.setItem("titleType", 10);
    //   break;
    // case "/qysq":
    //   sessionStorage.setItem("titleType", 6);
    //   break;
    // case "/enterpriseLibrary":
    //   sessionStorage.setItem("titleType", 9);
    //   break;
    case '/login':
      if (process.env.VUE_APP_OPEN_AUTH) { // 认证开关
        window.location.href = process.env.VUE_APP_LOGIN_URL
        return
      }
      break;
    case '/slogin':
      if (process.env.VUE_APP_OPEN_AUTH) {
        window.location.href = process.env.VUE_APP_SLOGIN_URL
        return
      }
      default:
        // sessionStorage.setItem("titleType", 66);
        break;
  }

  next()

})

export default router;
