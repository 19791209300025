// // 导出页面为PDF格式
// import html2Canvas from 'html2canvas'
// import JsPDF from 'jspdf'
// export default {
//     install(Vue, options) {
//         Vue.prototype.getPdf = function(dom, title) {
//             console.log("pdf dom:", dom)
//             var that = this
//             html2Canvas(dom, {
//                 allowTaint: true,
//                 useCORS: true
//             }).then(function(canvas) {
//                 let contentWidth = canvas.width
//                 let contentHeight = canvas.height
//                 console.log("canvas.height:", contentHeight)
//                 let pageHeight = contentWidth / 592.28 * 841.89
//                 let leftHeight = contentHeight
//                 let position = 0
//                 let imgWidth = 595.28
//                 let imgHeight = 592.28 / contentWidth * contentHeight
//                 let pageData = canvas.toDataURL('image/jpeg')
//                 let PDF = new JsPDF('', 'pt', 'a4')
//                 if (leftHeight < pageHeight) {
//                     PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
//                 } else {
//                     while (leftHeight > 0) {
//                         PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
//                         leftHeight -= pageHeight
//                         position -= 841.89
//                         if (leftHeight > 0) {
//                             PDF.addPage()
//                         }
//                     }
//                 }

//                 PDF.save(title + '.pdf')
//                 that.isPrint = true
//             })
//         }
//     }
// }


// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
    install(Vue, options) {
        Vue.prototype.getPdf = function(dom, title) {
            // 导出之前先将滚动条置顶,不然会出现数据不全的现象
            window.pageYOffset = 0;
            document.documentElement.scrollTop = 0
            document.body.scrollTop = 0

            const loading = this.$loading({
                lock: true,
                text: '导出中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            // var title = this.htmlTitle
            html2Canvas(dom, {
                allowTaint: true,
                useCORS: true,
            }).then(function(canvas) {
                let contentWidth = canvas.width
                let contentHeight = canvas.height
                let pageHeight = contentWidth / 592.28 * 841.89
                let leftHeight = contentHeight
                let position = 0
                let imgWidth = 595.28
                let imgHeight = 592.28 / contentWidth * contentHeight

                let pageData = new Image();
                //设置图片跨域访问
                pageData.setAttribute('crossOrigin', 'Anonymous');

                setTimeout(() => {
                    pageData = canvas.toDataURL('image/jpeg', 1.0);
                    let PDF = new JsPDF('', 'pt', 'a4')
                    if (leftHeight < pageHeight) {
                        PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                    } else {
                        while (leftHeight > 0) {
                            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                            leftHeight -= pageHeight
                            position -= 841.89
                            if (leftHeight > 0) {
                                PDF.addPage()
                            }
                        }
                    }
                    loading.close();
                    PDF.save(title + '.pdf')
                }, 1000);
            })
        }
    }
}