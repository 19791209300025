<!--
 * @Author: LXG
 * @Date: 2021-04-21
 * @LastEditor: LXG
 * @LastEditTime: 2021-05-18
 * @Description: tj - 图标
-->
<template>
    <svg
        class="tj-icon"
        aria-hidden="true"
    >
        <use :xlink:href="'#'+group+'-'+name"></use>
    </svg>
</template>

<script>
export default {
    name: 'TjIcon',
    props: {
        group: {
            type: String,
            default: 'ydyy'
        },
        name: {
            type: String,
            default: ''
        },
    },
}
</script>

<style lang="scss" scoped>
.tj-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>