<template>
  <div id="app" @click="tTime()">
    <router-view />
  </div>
</template>

<script>
import {mapActions } from 'vuex';
import store from './store';
import cookie from './utils/cookie';

export default {
  name: 'app',
    // 退出登录点击事件
  ...mapActions([
    'logout',
  ]),
  data (){
    return {
        lTime: new Date().getTime(), // 最后一次点击的时间
        cTime: new Date().getTime(), // 当前时间
        // tOut: 60 * 10 * 1000,   // 超时时间10min
        tOut: 60 * 60 * 1000,  // 20 分钟
        t1: ''    
    } 
  },
  created() {
    this.lTime = new Date().getTime();
  },
  mounted () {
    // this.setTimes(60)
  },
  methods: {
    /**
     * 计时规则, 循环 某个时间
     * @param { Number } [ 1m ] 
     */
    setTimes(num) {
      this.t1 = setInterval(this.tTime, num * 1000);
    },
    /**
     * 判断是否 20 分钟， 是否登录
     */
    tTime() {
      this.cTime = new Date().getTime();
      // console.log("计时器》》》", this.lTime, this.cTime, this.cTime - this.lTime)
      if(this.cTime - this.lTime > this.tOut) {   // 判断上次最后一次点击的时间和这次点击的时间间隔是否大于60分钟
        if( !cookie.getCookie("userInfo") && !cookie.getCookie("token") ){  //未登录状态
          console.log("未登录")
          this.lTime = new Date().getTime();
        }else {
          console.log("退出")
          this.$store.dispatch("logout", () => {  
          });    // 退出
          location.reload() 
        }
      }else {
        // console.log("小于设定时间，重置>>>")
        this.lTime = new Date().getTime();    // 如果操作时间差小于设定的时间，就刷新最后点击的时间
      }
    },
  }
}
</script>

<style lang="scss">
// @import "@/styles/index.scss";
#app {
  min-width: 1024px;
  min-height: 100vh;
}
@import "./assets/font/font.css"
</style>
