const initialModules = {
	state: {
		currentWindowsInfo: {		// 基本屏幕信息
			baseWidth: '',	// 基础宽度
			tw:	'',			// 实际宽度 / 基础宽度
		},					// 当前窗口信息
		currentArea: {
			label: "漯河市", 
			lat: 114.026405,
			lon: 33.575855,
			id: 440306,
		},   	// 当前的区域  { label: '宝安区', id: 440306 }
		currentTime: ['',''],	// 当前时间选项 currentTime[0] 是 startcurrentTime currentTime[1] 是 endcurrentTime
		zdQyYqDto: [],  	// 重点园区 + 重点企业 
		ldDataNumDto: [],	// 市挂点领导，区挂点领导，区直达领导，街道挂点领导，街道直达领导，服企专员
		sqDirect: [], 	// 诉求业务
		policyDirect: [],	// 政策直达
		qyTypeDtos : [],	// 重点企业分类 ABCD
		gdzdLeader: [],		// 挂点领导 + 直达领导
		sqServiceHeat: [],	// 诉求服务热度
		sqEnterpriseQualification: [],	// 诉求直达业务：企业资质
		zcApplyHeat: [],	// 政策申请热度
		zcToEffect: [],		// 政策直达成效
		sqListByType: [],	// 诉求业务按部门
		zcDeptNum: [],		// 政策按部门统计 业务
	},
	getters: {
		currentWindowsInfo: state => {
			return state.currentWindowsInfo
		},
		currentArea: state => {		// 当前的区域
			return state.currentArea
		}, 
		currentTime: state => {		// 当前的区域
			return state.currentTime
		}, 
		zdQyYqDto: state => {		// 重点园区 + 重点企业
			const userKey = 'zdQyYqDto'; 
			let localData = JSON.parse(sessionStorage.getItem(userKey));
			if( state.zdQyYqDto.length !== 0 ) {
				return state.zdQyYqDto
			}else if ( localData ) {
				return localData;
			}else {
				return "";
			}
		}, 
		ldDataNumDto: state => {		// 市挂点领导，区挂点领导，区直达领导，街道挂点领导，街道直达领导，服企专员
			const userKey = 'ldDataNumDto'; 
			let localData = JSON.parse(sessionStorage.getItem(userKey));
			if( state.ldDataNumDto.length !== 0 ) {
				return state.ldDataNumDto
			}else if ( localData ) {
				return localData;
			}else {
				return "";
			}
		}, 
		sqDirect: state => {		// 诉求业务
			const userKey = 'sqDirect'; 
			let localData = JSON.parse(sessionStorage.getItem(userKey));
			if( state.sqDirect.length !== 0 ) {
				return state.sqDirect
			}else if ( localData ) {
				return localData;
			}else {
				return "";
			}
		},  
		policyDirect: state => {		// 政策直达
			const userKey = 'policyDirect'; 
			let localData = JSON.parse(sessionStorage.getItem(userKey));
			if( state.policyDirect.length !== 0 ) {
				return state.policyDirect
			}else if ( localData ) {
				return localData;
			}else {
				return [];
			}
		},  
		qyTypeDtos: state => {		// 重点企业分类 
			return state.qyTypeDtos
		}, 
		gdzdLeader: state => {		// 挂点领导 + 直达领导
			const userKey = 'gdzdLeader'; 
			let localData = JSON.parse(sessionStorage.getItem(userKey));
			if( state.gdzdLeader.length !== 0 ) {
				return state.gdzdLeader
			}else if ( localData ) {
				return localData;
			}else {
				return "";
			}
		}, 
		sqServiceHeat: state => {		// 诉求服务热度
			const userKey = 'sqServiceHeat'; 
			let localData = JSON.parse(sessionStorage.getItem(userKey));
			if( state.sqServiceHeat.length !== 0 ) {
				return state.sqServiceHeat
			}else if ( localData ) {
				return localData;
			}else {
				return "";
			}
		}, 
		sqEnterpriseQualification: state => {		// 诉求企业分析 企业资质
			const userKey = 'sqEnterpriseQualification'; 
			let localData = JSON.parse(sessionStorage.getItem(userKey));
			if( state.sqEnterpriseQualification.length !== 0 ) {
				return state.sqEnterpriseQualification
			}else if ( localData ) {
				return localData;
			}else {
				return "";
			}
		}, 
		zcApplyHeat: state => {		// 诉求服务热度
			const userKey = 'zcApplyHeat'; 
			let localData = JSON.parse(sessionStorage.getItem(userKey));
			if( state.zcApplyHeat.length !== 0 ) {
				return state.zcApplyHeat
			}else if ( localData ) {
				return localData;
			}else {
				return "";
			}
		}, 
		zcToEffect: state => {		// 诉求服务热度
			const userKey = 'zcToEffect'; 
			let localData = JSON.parse(sessionStorage.getItem(userKey));
			if( state.zcToEffect.length !== 0 ) {
				return state.zcToEffect
			}else if ( localData ) {
				return localData;
			}else {
				return "";
			}
		},
		sqListByType: state => {		// 诉求服务热度
			const userKey = 'sqListByType'; 
			let localData = JSON.parse(sessionStorage.getItem(userKey));
			if( state.sqListByType.length !== 0 ) {
				return state.sqListByType
			}else if ( localData ) {
				return localData;
			}else {
				return "";
			}
		},
		zcDeptNum: state => {		// 诉求服务热度
			const userKey = 'zcDeptNum'; 
			let localData = JSON.parse(sessionStorage.getItem(userKey));
			if( state.zcDeptNum.length !== 0 ) {
				return state.zcDeptNum
			}else if ( localData ) {
				return localData;
			}else {
				return "";
			}
		},    
    },
	mutations: {
		upcurrentWindowsInfo (state, currentWindowsInfo) {  
			if( currentWindowsInfo == null ) {  
				state.currentWindowsInfo = {}
			}else {    
				state.currentWindowsInfo = currentWindowsInfo
			};
		},
		upcurrentArea (state, currentArea) {  
			if( currentArea == null ) {  
				state.currentArea = {}
			}else {    
				state.currentArea = currentArea
			};
		}, 
		upcurrentTime (state, currentTime) {  
			if( currentTime == null ) {  
				state.currentTime = ['','']
			}else {    
				state.currentTime = currentTime
			};
		}, 
		upzdQyYqDto (state, zdQyYqDto) { 
			const userKey = 'zdQyYqDto';
			if( zdQyYqDto == null ) { 
				sessionStorage.removeItem(userKey);
				state.zdQyYqDto = {}
			}else {   
				sessionStorage.setItem(userKey, JSON.stringify(zdQyYqDto)); 
				state.zdQyYqDto = zdQyYqDto
			};
		}, 
		upldDataNumDto (state, ldDataNumDto) { 
			const userKey = 'ldDataNumDto';
			if( ldDataNumDto == null ) { 
				sessionStorage.removeItem(userKey);
				state.ldDataNumDto = {}
			}else {   
				sessionStorage.setItem(userKey, JSON.stringify(ldDataNumDto)); 
				state.ldDataNumDto = ldDataNumDto
			};
		},
		upsqDirect (state, sqDirect) { 
			const userKey = 'sqDirect';
			if( sqDirect == null ) { 
				sessionStorage.removeItem(userKey);
				state.sqDirect = {}
			}else {   
				sessionStorage.setItem(userKey, JSON.stringify(sqDirect)); 
				state.sqDirect = sqDirect
			};
		},
		uppolicyDirect (state, policyDirect) { 
			const userKey = 'policyDirect';
			if( policyDirect == null ) { 
				sessionStorage.removeItem(userKey);
				state.policyDirect = {}
			}else {   
				sessionStorage.setItem(userKey, JSON.stringify(policyDirect)); 
				state.policyDirect = policyDirect
			};
		},
		upqyTypeDtos (state, qyTypeDtos) {  
			if( qyTypeDtos == null ) {  
				state.qyTypeDtos = {}
			}else {    
				state.qyTypeDtos = qyTypeDtos
			};
		},
		upgdzdLeader (state, gdzdLeader) { 
			const userKey = 'gdzdLeader';
			if( gdzdLeader == null ) { 
				sessionStorage.removeItem(userKey);
				state.gdzdLeader = {}
			}else {   
				sessionStorage.setItem(userKey, JSON.stringify(gdzdLeader)); 
				state.gdzdLeader = gdzdLeader
			};
		},
		upsqServiceHeat (state, sqServiceHeat) { 
			const userKey = 'sqServiceHeat';
			if( sqServiceHeat == null ) { 
				sessionStorage.removeItem(userKey);
				state.sqServiceHeat = {}
			}else {   
				sessionStorage.setItem(userKey, JSON.stringify(sqServiceHeat)); 
				state.sqServiceHeat = sqServiceHeat
			};
		},
		upsqEnterpriseQualification (state, sqEnterpriseQualification) { 
			const userKey = 'sqEnterpriseQualification';
			if( sqEnterpriseQualification == null ) { 
				sessionStorage.removeItem(userKey);
				state.sqEnterpriseQualification = {}
			}else {   
				sessionStorage.setItem(userKey, JSON.stringify(sqEnterpriseQualification)); 
				state.sqEnterpriseQualification = sqEnterpriseQualification
			};
		},
		upzcApplyHeat (state, zcApplyHeat) { 
			const userKey = 'zcApplyHeat';
			if( zcApplyHeat == null ) { 
				sessionStorage.removeItem(userKey);
				state.zcApplyHeat = {}
			}else {   
				sessionStorage.setItem(userKey, JSON.stringify(zcApplyHeat)); 
				state.zcApplyHeat = zcApplyHeat
			};
		},
		upzcToEffect (state, zcToEffect) { 
			const userKey = 'zcToEffect';
			if( zcToEffect == null ) { 
				sessionStorage.removeItem(userKey);
				state.zcToEffect = {}
			}else {   
				sessionStorage.setItem(userKey, JSON.stringify(zcToEffect)); 
				state.zcToEffect = zcToEffect
			};
		},
		upsqListByType (state, sqListByType) { 
			const userKey = 'sqListByType';
			if( sqListByType == null ) { 
				sessionStorage.removeItem(userKey);
				state.sqListByType = {}
			}else {   
				sessionStorage.setItem(userKey, JSON.stringify(sqListByType)); 
				state.sqListByType = sqListByType
			};
		},
		upZcDeptNum (state, zcDeptNum) { 
			const userKey = 'zcDeptNum';
			if( zcDeptNum == null ) { 
				sessionStorage.removeItem(userKey);
				state.zcDeptNum = {}
			}else {   
				sessionStorage.setItem(userKey, JSON.stringify(zcDeptNum)); 
				state.zcDeptNum = zcDeptNum
			};
		},
		
	} 
}

export default initialModules
