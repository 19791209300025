<template>
	<div v-if="dataState == 'noData'" class="nodata-containenr">
		<img src="@/assets/images/nodata.png" class="nodata-image" :style="{'width':width,'height':height}" />
		暂无数据
	</div>
</template>

<script>
	//数据状态:初始化'init' 无数据'noData' 有数据'hasData'
	export default {
		name:"NoDataView",
		props:{
			dataState:{
				type:String,
				default:'init'
			},
			width: {
				type: String,
				default: '280px'
			},
			height: {
				type: String,
				default: '140px'
			},
		},
		data() {
			return {
				
			};
		}
	}
</script>

<style lang="scss" scoped>
	.nodata-containenr{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		margin: auto;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #999999;
	}
</style>
