const httpApi = {}
import axios from 'axios';
import store from '@/store/index'

// 打包如果是正式环境 需要设置为 true， 默认的开发环境的
const isProduction = true
// 根据生产或开发环境 自动切换baseUrl
// if (isProduction) {
//   httpApi.baseUrl = 'https://qqzq.baoan.gov.cn/balocal-api'
// } else {
//   httpApi.baseUrl = 'https://qqzq.baoan.gov.cn/balocal-api-dev'
// }

httpApi.baseUrl = process.env.VUE_APP_DATA_URL

httpApi.appid = 'afc903d9d5e449488a3cf9a00c3d1294'
httpApi.appkey = '8cb4f61b-e89a-405b-acc8-4d992826c247'
httpApi.token = ''
let timeout = 30 * 1000

httpApi.get = ((url, params) => {
  let token = store.getters.getToken;
  return new Promise((resolve, reject) => {
    let fullUrl = httpApi.baseUrl + url;
    let data = JSON.stringify(params);
    let config = {
      method: 'get',
      url: fullUrl,
      headers: {
        // 'WebAuthorization': 'Bearer ' + token, // 让每个请求携带自定义token 请根据实际情况自行修改
        'WebAuthorization': token, // 让每个请求携带自定义token 请根据实际情况自行修改
        "appid": httpApi.appid,
        "appkey": httpApi.appkey,
        'Content-Type': 'application/json'
      },
      data: data,
      timeout: timeout,
      params: params // params 会将参数自动拼接到url后面
    };


    axios(config)
      .then(function (response) {
        // console.log("请求结果:", response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error("请求错误:", error)
        if ( error.code == 50014 ) {
          this.$store.dispatch("logout", () => {
          });
        }
        reject(error);
      });

  })

})

// 这个get请求 要把参数都拼接在URL后面
httpApi.getByBody = ((url, params) => {
  let token = store.getters.getToken;
  return new Promise((resolve, reject) => {
    let fullUrl = httpApi.baseUrl + url;
    if (params) {
      fullUrl = fullUrl + "?"
      for (const key in params) {
        fullUrl += key + "=" + params[key] + "&"
      }
      if (fullUrl.lastIndexOf("&")) {
        fullUrl = fullUrl.substring(0, fullUrl.length - 1);
      }
    }
    console.log("fullUrl:", fullUrl);

    let config = {
      method: 'get',
      url: fullUrl,
      headers: {
        'WebAuthorization': token, // 让每个请求携带自定义token 请根据实际情况自行修改
        "appid": httpApi.appid,
        "appkey": httpApi.appkey,
        'Content-Type': 'application/json'
      },
      timeout: timeout,
      // data: data
    };
    axios(config)
      .then(function (response) {
        // console.log("请求结果:", response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error("请求错误:", error)
        if ( error.code == 50014 ) {
          this.$store.dispatch("logout", () => {
          });
        }
        reject(error);
      });
  })
})

httpApi.post = ((url, params) => {
  params['appid'] = httpApi.appid;
  params['appkey'] = httpApi.appkey;
  let token = store.getters.getToken;
  // let data = JSON.stringify(params);
  // console.log("token:", token);
  return new Promise((resolve, reject) => {
    let fullUrl = httpApi.baseUrl + url;
    // console.log("提交时候的参数", fullUrl, params);
    let config = {
      method: 'post',
      url: fullUrl,
      headers: {
        'WebAuthorization': token,
        "appid": httpApi.appid,
        "appkey": httpApi.appkey,
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      timeout: timeout,
      data: params,
    };
    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.log("请求错误:", error)
        if ( error.code == 50014 ) {
          this.$store.dispatch("logout", () => {
          });
        }
        reject(error);
      });
  })
})
httpApi.put = ((url, params) => {
  params['appid'] = httpApi.appid;
  params['appkey'] = httpApi.appkey;
  let token = store.getters.getToken;
  // let data = JSON.stringify(params);
  // console.log("token:", token);
  return new Promise((resolve, reject) => {
    let fullUrl = httpApi.baseUrl + url;
    // console.log("提交时候的参数", fullUrl, params);
    let config = {
      method: 'put',
      url: fullUrl,
      headers: {
        'WebAuthorization': token,
        "appid": httpApi.appid,
        "appkey": httpApi.appkey,
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      timeout: timeout,
      data: params,
    };
    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.log("请求错误:", error)
        if ( error.code == 50014 ) {
          this.$store.dispatch("logout", () => {
          });
        }
        reject(error);
      });
  })
})

//后台的奇葩传值,把参数拼接在地址后面,用post请求
httpApi.postByForm = ((url, params) => {
  let token = store.getters.getToken;
  return new Promise((resolve, reject) => {
    let fullUrl = httpApi.baseUrl + url;
    if (params) {
      fullUrl = fullUrl + "?"
      for (const key in params) {
        fullUrl += key + "=" + params[key] + "&"
      }
      if (fullUrl.lastIndexOf("&")) {
        fullUrl = fullUrl.substring(0, fullUrl.length - 1);
      }
    }
    // console.log("fullUrl:", fullUrl);

    let config = {
      method: 'post',
      url: fullUrl,
      headers: {
        'WebAuthorization': token, // 让每个请求携带自定义token 请根据实际情况自行修改
        "appid": httpApi.appid,
        "appkey": httpApi.appkey,
      },
      timeout: timeout,
      // data: data
    };
    axios(config)
      .then(function (response) {
        // console.log("请求结果:", response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.error("请求错误:", error)
        if ( error.code == 50014 ) {
          this.$store.dispatch("logout", () => {
          });
        }
        reject(error);
      });
  })

})

//后端的奇葩传值,用get请求json
httpApi.getByJson = ((url, params) => {
  params['appid'] = httpApi.appid;
  params['appkey'] = httpApi.appkey;
  let token = store.getters.getToken;
  // let data = JSON.stringify(params);
  // console.log("token:", token);
  return new Promise((resolve, reject) => {
    let fullUrl = httpApi.baseUrl + url;
    // console.log("提交时候的参数", fullUrl, params);
    let config = {
      method: 'get',
      url: fullUrl,
      headers: {
        'WebAuthorization': token,
        "appid": httpApi.appid,
        "appkey": httpApi.appkey,
        'Content-Type': 'application/json',
      },
      timeout: timeout,
      params:params

    };
    // console.log("请求参数:", fullUrl, params)
    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.log("请求错误:", error)
        if ( error.code == 50014 ) {
          this.$store.dispatch("logout", () => {
          });
        }
        reject(error);
      });
  })
})

//
httpApi.postByBody = ((url, params) => {
  params['appid'] = httpApi.appid;
  params['appkey'] = httpApi.appkey;
  let token = store.getters.getToken;
  // params = JSON.stringify(params)
  // let data = JSON.stringify(params);

  // console.log("token:", token);
  return new Promise((resolve, reject) => {
    let fullUrl = httpApi.baseUrl + url;
    // console.log("提交时候的参数", fullUrl, params);
    let config = {
      method: 'post',
      url: fullUrl,
      headers: {
        'WebAuthorization': token,
        "appid": httpApi.appid,
        "appkey": httpApi.appkey,
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      timeout: timeout,
      data: params,
    };
    // console.log("请求参数:", fullUrl, params)
    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.log("请求错误:", error)
        if ( error.code == 50014 ) {
          this.$store.dispatch("logout", () => {
          });
        }
        reject(error);
      });
  })
})

/* 文件 图片上传要设置Content-Type为formdata格式 */
httpApi.postFormData = ((url, params) => {
  let token = store.getters.getToken;
  return new Promise((resolve, reject) => {
    let fullUrl = httpApi.baseUrl + url;
    let config = {
      method: 'post',
      url: fullUrl,
      headers: {
        'WebAuthorization': token,
        "appid": httpApi.appid,
        "appkey": httpApi.appkey,
        'Content-Type': 'multipart/form-data'
      },
      timeout: timeout,
      data: params,
    };
    // console.log("请求参数:", fullUrl, params)
    axios(config)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        console.log("请求错误:", error)
        if ( error.code == 50014 ) {
          this.$store.dispatch("logout", () => {
          });
        }
        reject(error);
      });
  })

})

//文件上传
httpApi.uploadFile = (file => {
  let requestUrl = httpApi.baseUrl + "/dev-api/fileManageApi/common/upload";
  let token = store.getters.getToken;
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append('file', file);
    axios.post(requestUrl, formData, {
      headers: {
        'WebAuthorization': token,
        "appid": httpApi.appid,
        "appkey": httpApi.appkey,
        'Content-Type': 'multipart/form-data',
      },
      timeout: timeout,
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data)
    });
  })
})

export default httpApi
