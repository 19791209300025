
<template>
  <div class="rightNav" :class="isFold ? 'rightNavFold' : ''">
    <div class="item" @click="toPage('index')" v-show="!isFold">
      <img src="~@/assets/images/rightNav_index.png" width="50px" alt="" />
      <span>首页</span>
    </div>
    <div class="item" v-show="!isFold" @click="toPage('back')">
      <img src="~@/assets/images/rightNav_up.png" width="50px" alt="" />
      <span>后退</span>
    </div>
    <div class="item" v-show="!isFold" @click="toPage('forward')">
      <img
        src="~@/assets/images/rightNav_up.png"
        style="transform: rotate(180deg)"
        width="50px"
        alt=""
      />
      <span>前进</span>
    </div>
    <div class="item" @click="fold">
      <img src="~@/assets/images/rightNav_fold.png" width="40px" alt="" />
      <span>收起</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      isFold: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    toPage(params) {
      switch (params) {
        case "index":
          this.$router.push({
            name: "homePage",
          });
          break;
        case "back":
          this.$router.go(-1);
          break;
        case "forward":
          this.$router.go(1);
          break;
        default:
          break;
      }
    },
    fold() {
      this.isFold = !this.isFold;
    },
  },
};
</script>

<style lang="scss" scoped>
.rightNav {
  width: 70px;
  height: 320px;
  position: fixed;
  top: 130px;
  right: 0px;
  z-index: 99999;
  background: #fff;
  .item {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    &:hover {
      background: #e2efff;
    }
  }
}
.rightNavFold {
  height: 80px;
  top: 200px;
}
</style>
