import Vue from 'vue';
import Vuex from 'vuex';
import dictionary from './modules/dictionary.js';
import initialData from './modules/initialData.js';
import Cookie from '@/utils/cookie';
import httpApi from '@/utils/http';
import router from '../router';
import {
	Message
} from 'element-ui';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		hNavAction: null,
		token: '',
		userInfo: null, // 用户登录时候返回的基本信息
		currentGuideId: null, // 当前显示的办事指南ID
		currentDeclareItem: null, // 从办事指南进入到申报页面要存储这个值
		currentProjectItem: null, // 从我的在办件已办件等进入到申报页面要存储这个值
		idCardTypes: [], // 证件类型
		accountId: '', // 企业用户登录后，获取基本信息后的id，用作办公地址，经济指标等功能查询，唯一码。 webEid
		accountBaseInfo: undefined, // 企业用户基本信息
		tempFollow: [],//收藏
		phoneCode: '',//手机验证码
	},
	getters: {
		getHNavAction: state => {
			if (state.hNavAction) {
				return state.hNavAction;
			} else {
				return localStorage.getItem('hNavAction') || '/';
			}
		},
		getToken: state => {
			if (state.token) {
				return state.token
			} else if (Cookie.getCookie('token')) {
				return Cookie.getCookie('token');
			} else {
				return "";
			}
		},

		getUserInfo: state => {
			// console.log("state.userInfo:", state.userInfo)
			var userInfoCookie = Cookie.getCookie('userInfo');
			// console.log("userInfoCookie:", userInfoCookie)
			if (state.userInfo && userInfoCookie) {
				return state.userInfo
			} else if (Cookie.getCookie('userInfo')) {
				return JSON.parse(Cookie.getCookie('userInfo'));
			} else {
				return null;
			}
		},
		getUserRoles: state => {
			let userInfo = state.userInfo || JSON.parse(Cookie.getCookie('userInfo'))
			if (!userInfo || !userInfo.userType) return []

			let roleMap = new Map([
				['01', 'qygg'], // '01': 企业高管
				['02', 'qylly'], // '02': 企业联络员
			])
			return userInfo.userType.split(',').map(cv => roleMap.get(cv))
		},
		getCurrentGuideId: state => {
			if (state.currentGuideId) {
				return state.currentGuideId
			} else if (localStorage.getItem("currentGuideId")) {
				return localStorage.getItem("currentGuideId");
			} else {
				return ""
			}
		},
		getCurrentDeclareItem: state => {
			if (state.currentDeclareItem) {
				return state.currentDeclareItem
			} else if (localStorage.getItem("currentDeclareItem")) {
				return JSON.parse(localStorage.getItem("currentDeclareItem"));
			} else {
				return null
			}
		},
		getCurrentProjectItem: state => {
			if (state.currentProjectItem) {
				return state.currentProjectItem
			} else if (localStorage.getItem("currentProjectItem")) {
				return JSON.parse(localStorage.getItem("currentProjectItem"));
			} else {
				return null
			}
		},
		getIdCardTypes: state => {
			if (state.currentDeclareItem) {
				return state.currentDeclareItem
			} else if (localStorage.getItem("idCardTypes")) {
				return JSON.parse(localStorage.getItem("idCardTypes"));
			}
		},
		getAccountId: state => {
			if (state.accountId) {
				return state.accountId
			} else if (localStorage.getItem("accountId")) {
				return localStorage.getItem("accountId");
			} else {
				return ""
			}
		},

		getAccountBaseInfo: state => {
			if (state.accountBaseInfo) {
				return state.accountBaseInfo
			} else if (localStorage.getItem("accountBaseInfo")) {
				let data = localStorage.getItem("accountBaseInfo");
				if (data != null && data.length > 0) {
					return JSON.parse(data);
				} else {
					return null
				}
			} else {
				return null;
			}
		},
		getTempFollow: state => {
			return state.tempFollow;
		},

		getPhoneCode: state => {
			return state.phoneCode;
		}
	},
	mutations: {
		updateHNavAction(state, index) {
			state.hNavAction = index;
			localStorage.setItem("hNavAction", index);
		},
		updateToken(state, token) {
			if (!token) {
				Cookie.clearCookie('token');
			} else {
				// 保存用token 0.013天
				/**
				 * 20210507
				 * 0.013天，18分钟？会不会太短？
				 * 暂时调整为1小时
				 */
				Cookie.setCookie({
					token: token
				}, 0.0417)
				// Cookie.setCookie({
				//   	token: token
				// }, 0.013);
			}

			Vue.set(state, 'token', token)
			// state.token = token
		},
		updateUserInfo(state, userInfo) {
			Vue.set(state, 'userInfo', userInfo)
			// state.userInfo = userInfo
			if (!userInfo) {
				Cookie.clearCookie('userInfo');
			} else {
				// 保存用户信息0.013天
				/**
				 * 20210507
				 * 0.013天，18分钟？会不会太短？
				 * 暂时调整为1小时
				 */
				Cookie.setCookie({
					userInfo: JSON.stringify(userInfo)
				}, 0.0417)
				//  Cookie.setCookie({
				//   userInfo: JSON.stringify(userInfo)
				// }, 0.013);
			}

		},
		updateCurrentGuideId(state, id) {
			localStorage.setItem("currentGuideId", id);
			state.currentGuideId = id
		},
		updateCurrentDeclareItem(state, item) {
			localStorage.setItem("currentDeclareItem", JSON.stringify(item));
			state.currentDeclareItem = item
		},
		updateCurrentProjectItem(state, item) {
			localStorage.setItem("currentProjectItem", JSON.stringify(item));
			state.currentProjectItem = item
		},
		updateIdCardTypes(state, list) {
			localStorage.setItem("idCardTypes", JSON.stringify(list));
			state.idCardTypes = list;
		},
		updateAccountId(state, item) {
			localStorage.setItem("accountId", JSON.stringify(item));
			state.accountId = item
		},
		updateAccountBaseInfo(state, item) {
			localStorage.setItem("accountBaseInfo", JSON.stringify(item));
			state.accountBaseInfo = item
		},
		updateTempFollow(state, list) {
			localStorage.setItem("tempFollow", JSON.stringify(list));
			state.tempFollow = list
		},
		updatePhoneCode(state, code) {
			localStorage.setItem("tempFollow", JSON.stringify(code));
			console.log("new phone code is:", code);
			state.phoneCode = code;
		}
	},
	actions: {
		// 根据key获取词典信息 在readme里有key
		getDictListByType(context, params) {
			console.log("getDictListByType参数：", params);
			httpApi
				.get(`/dev-api/system/dict/getDictListByType?dictType=${params.dictType}`, {})
				.then((res) => {
					console.log("getDictListByType 结果:", res);
					if (res.code == 200) {
						switch (params.dictType) {
							case "sys_id_card_type": {
								context.commit("updateIdCardTypes", res.data)
								break;
							}
						}
					}
				});
		},
		logout(context, payload) {
			return new Promise((resolve, reject) => {
				context.commit("updateUserInfo", '')
				context.commit("updateToken", '')
				httpApi.get('/dev-api/logout', {}).then(res => {
          let loginSign = sessionStorage.getItem('loginSign')
          if (!!loginSign&&loginSign=='province'){
            window.location.href = process.env.VUE_APP_GET_LOGOUT
          }else {
            window.location.href = process.env.VUE_APP_GET_SLOGOUT
          }

					resolve()
				}).catch(err => {
					reject(err)
				})
			})
		},
	},
	modules: {
		dictionary,
		initialData
	},
});
