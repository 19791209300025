import Vue from 'vue'
import httpApi from '@/utils/http.js'

const dictionary = {
    namespaced: true,
    state: {
        // 政策行政等级
        areaLevel: [
            { key: '0', label: '国家级' },
            { key: '1', label: '省级' },
            { key: '2', label: '市级' },
            { key: '3', label: '区县级' },
        ],
        // 是否
        'yes_or_no': [
            { key: '0', label: '否' },
            { key: '1', label: '是' },
        ],
    },
    mutations: {
        'SET_DIC'(state, payload) {
            Vue.set(state, payload.type, payload.data)
        },
    },
    actions: {
        /**
         * @description: 通用查询数据字典
         * @param {Object} context store上下文
         * @param {Object} payload 参数
         * @return {Promise}
         */
        commonSetDic(context, payload) {
            return new Promise((resolve, reject) => {
                let url = '/dev-api/system/dict/getDictListByType'
                for (let i = 0; i < payload.types.length; i++) {
                    if (context.state[payload.types[i]]) continue

                    let params = { dictType: payload.types[i] }
                    httpApi.get(url, params).then(res => {
                        context.commit('SET_DIC', {
                            type: payload.types[i],
                            data: res.data,
                        })
                    })
                }
                resolve()
            })
        },
    },
    getters: {
        /**
         * @description: 获取字典列表
         * @param {String} dictType 字典类型
         * @return {Array}
         */
        getDictList: state => (dictType) => {
            if (!state[dictType]) Vue.set(state, dictType, [])

            return state[dictType]
        },
        /**
         * @description: 获取编码翻译值
         * @param {String} dictType 字典类型
         * @param {String} dictValue 编码
         * @return {*}
         */
        getDictLabel: state => (dictType, dictValue) => {
            if (!state[dictType]) Vue.set(state, dictType, [])

            let obj = state[dictType].find(cv => cv.key === dictValue)
            return (obj ? obj.label : '')
        }
    },
}

export default dictionary