import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false;

Vue.use(ElementUI);

import '@/styles/index.scss' // global css

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);

import './assets/iconfont/suffix/iconfont.js'

import getPdf from '@/utils/html2pdf.js'
Vue.use(getPdf)

import httpApi from '@/utils/http.js'
Vue.prototype.$httpApi = httpApi

// import { borderBox8 } from '@jiaminghi/data-view'
// Vue.use(borderBox8)

import cookie from './utils/cookie'
Vue.prototype.cookie = cookie

// 引用自动滚动插件
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import './assets/iconfont/ydyy/iconfont.js'
import './assets/iconfont/iconfont.js'
// 统一认证
import SSO from '@/cas/sso.js'
// 屏蔽打印
// 如果不是开发环境就注释掉打印方法
if (process.env.NODE_ENV !== 'local' && process.env.NODE_ENV !== 'development' && window) {
  window.console.log = () => {}
}
// 关闭游览器后清除cookie
// window.addEventListener('beforeunload',  () => {
//     store.commit("updateUserInfo", null);
//     store.commit("updateToken", null);
// }, false)
if(process.env.NODE_ENV == 'production') {
  SSO.loginAuth(() => {
    main()
  })
}else {
  main()
}

function main() {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}
