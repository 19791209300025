<template>
  <!-- <div class="footer-container">
    <div class="def-container flex-row-between wrap_inner">
      <div class="footer-left flex-column">
        <span style="font-weight: 600"
          >主办单位：德阳市政务服务与大数据管理局</span
        >
        <span> 建议使用IE10及以上版本或Chrome、360等主流浏览器访问本网站 </span>
      </div>
      <div>
        <span style="font-weight: 600"> 浏览量(PV):{{ visitNum }}次</span>
      </div>
      <div class="flex-row">
        <img :src="require('@/assets/images/jiucuo.png')" alt="" />
        <img :src="require('@/assets/images/dzjg_icon.png')" alt="" />
      </div>
    </div>
  </div> -->
  <div
    style="background-color: rgb(43, 48, 59); height: 260px; padding: 0px 10px"
  >
    <el-row>
      <el-col :span="20" :offset="2">
        <div
          style="
            display: flex;
            height: 260px;
            font-size: 13px;
            color: rgb(181, 182, 186);
            align-items: center;
            justify-content: space-around;
          "
        >
          <div style="width: 300px; text-align: center">
            <div
              style="
                display: flex;
                background-color: rgb(52, 57, 67);
                padding: 20px;
                justify-content: space-around;
              "
            >
              <div>
                <div class="el-image" style="width: 100%; height: 100px">
                  <img
                    src="~@/assets/deyangImg/xcx_90_90.jpg"
                    class="el-image__inner el-image__preview"
                    style="object-fit: scale-down"
                  /><!---->
                </div>
                <span>德阳市民通小程序</span>
              </div>
              <div>
                <div class="el-image" style="width: 100%; height: 100px">
                  <img
                    src="~@/assets/deyangImg/90_90.png"
                    class="el-image__inner el-image__preview"
                    style="object-fit: scale-down"
                  /><!---->
                </div>
                <span>德阳市民通APP</span>
              </div>
            </div>
          </div>
          <div style="width: 520px">
            <div
              style="
                background-color: rgb(52, 57, 67);
                display: flex;
                height: 160px;
                padding: 0px 22px;
                text-align: left;
              "
            >
              <div style="width: 300px; align-content: space-between">
                <p>主办：德阳市政务服务和大数据管理局</p>
                <p>支持：德阳智慧城市科技发展有限公司</p>
                <p>联系电话：0838-12345</p>
                <p>网站标识码：5106000035</p>
              </div>
              <div style="width: 300px; align-content: space-between">
                <p>蜀ICP备2020037027号</p>
                <p>
                  <a
                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010402000532"
                    target="_blank"
                    style="color: rgb(181, 182, 186)"
                    ><img
                      src="~@/assets/deyangImg/huibiao.png"
                      alt=""
                      style="margin: 0px 4px 4px 0px"
                    />川公网安备51060302510715号</a
                  >
                </p>
                <p>邮政编码：618000</p>
                <p>
                  建议使用1280*960分辨率以上的显示器并使用IE9.0以上浏览器访问达到最佳效果
                </p>
              </div>
            </div>
          </div>
          <div style="width: 250px">
            <div
              style="
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
              "
            >
              <p class="f-link" style="display: flex">
                <a
                  href="http://www.sczwfw.gov.cn/col/col15294/index.html"
                  target="_blank"
                  style="color: rgb(181, 182, 186); width: 60px"
                  >网站声明 |</a
                ><a
                  href="http://www.sczwfw.gov.cn/col/col15295/index.html"
                  target="_blank"
                  style="color: rgb(181, 182, 186); width: 60px"
                  >关于我们</a
                >
              </p>
            </div>
            <div style="display: flex; justify-content: center">
              <a
                href="http://bszs.conac.cn/sitename?method=show&amp;id=0B69D405429E0E7AE053012819AC9DDD"
                target="_blank"
                ><img
                  src="http://www.sczwfw.gov.cn/picture/2/2007211605218088118.png" /></a
              ><span id="_span_jiucuo"
                ><img
                  src="~@/assets/deyangImg/jiucuo.png"
                  style="margin: 0px; border: 0px; cursor: pointer"
              /></span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="dialogVisible"
      width="35%"
      :show-close="false"
      center
    >
      <span slot="title" style="text-align: center"
        >欢迎登陆德阳市惠企政策通</span
      >
      <span
        >由于您是首次登录，请点击下方完善企业信息按钮。请您务必填写真实有效的信息，便于惠企政策的推送与业务的办理，感谢您的配合！</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toUserCenter">完善企业信息</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visitNum: 0,
      dialogVisible: false,
    };
  },
  created() {
    this.openDialog();
    this.addNum();
    this.getNum();
  },
  methods: {
    // 首次登录弹出对话框提示
    openDialog() {
      let isFirst = sessionStorage.getItem("isFirst");
      if (isFirst == "true") {
        this.dialogVisible = true;
        sessionStorage.setItem("isFirst", "false");
      } else {
        this.dialogVisible = false;
      }
    },
    toUserCenter() {
      this.dialogVisible = false;
      this.$router.push("/workplace/setting");
    },
    addNum() {
      let url = "/dev-api/times/addOrUpdateSysIpTimes";
      let ip = document.domain;
      let params = {
        ip,
      };
      this.$httpApi.post(url, params).then((r) => {});
    },
    getNum() {
      let url = "/dev-api/times/countSysIpTimes";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((r) => {
          this.visitNum = r.data;
        })
        .catch((e) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
// .footer-container {
//   position: relative;
//   // margin-top: 50px;
//   width: 100%;
//   // height: $footerHeight;
//   min-height: 54px;
//   font-size: 16px;
//   background-color: #eaebeb;
//   .footer-left {
//     padding-top: 10px;
//   }

//   span {
//     display: flex;
//     flex-flow: column wrap;
//     justify-content: flex-start;
//     align-items: flex-start;

//     & > span {
//       height: 27px;
//       // padding-right: 40px;
//     }
//   }
//   span + span {
//     // margin-left: 100px;
//     margin: 5px 0;
//   }

//   .footer-right {
//     width: 40px;
//     height: 40px;
//   }
// }
// .wrap_inner {
//   height: 100%;
// }
p {
  margin: 13px auto !important;
}
</style>
