<!--
 * @Author: LXG
 * @Date: 2021-05-25
 * @Editors: LXG
 * @LastEditTime: 2021-06-28
 * @Description: 登录二维码
-->
<template>
    <div class="login-qrcode">
        <img
            v-if="qrcodeUrl"
            class="qrcode"
            :src="qrcodeUrl"
        />
        <div class="wrap-tootip">
            <!-- Scanned -->
            <div
                v-if="qrcodeStatus==='Scanned'"
                class="tootip-item tootip-Scanned"
            >
                <img :src="require('@/assets/images/icon_scan_success.png')" />
                <p>扫码成功!</p>
                <p>请在手机上确认登录</p>
            </div>
            <!-- Signed -->
            <div
                v-if="qrcodeStatus==='Signed'"
                class="tootip-item tootip-Signed"
            >
                <p>正在登录中</p>
            </div>
            <!-- invalid -->
            <div
                v-if="qrcodeStatus==='invalid'"
                class="tootip-item tootip-invalid"
            >
                <p>二维码已失效</p>
                <div
                    class="pad-rl-1 invalid-refresh"
                    @click="refreshQrcode"
                >刷新</div>
            </div>
        </div>
        <el-dialog
            :visible.sync="roleDialogShow"
            title="请选择您要登录的企业"
            width="25%"
            :show-close="false"
            :center="true"
        >
            <div class="pad-2 p-content">
                <div
                    v-for="item in enterpriseList"
                    :key="item.id"
                    @click="selectRole(item)"
                    class="p-cell"
                >
                    <div class="">
                        {{ item.enterprise }}
                    </div>
                    <div class="p-position">职位：{{(item.positionName&&item.positionName!=='null')?item.positionName:''}}</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'LoginQrcode',
    props: {},
    data() {
        return {
            qrcodeType: '2', // 二维码类型{'1':小程序码, '2':小程序登录码}
            qrcodeUrl: '', // 二维码url(base64)
            qrcodeKey: '', // 二维码唯一key
            // 二维码状态
            //     'generated': 已生成
            //     'invalid': 已失效
            //     'Scanned': 已扫描
            qrcodeStatus: '',
            isPolling: false, // 是否处于轮询二维码的状态
            loginInfo: {}, // 登录返回的信息
            roleDialogShow: false, // 角色选择框的显示状态位
            enterpriseList: [], // 多角色
        }
    },
    methods: {
        /**
         * @description: 轮询二维码状态
         */
        polling() {
            if (!this.isPolling) return

            if (this.qrcodeKey) {
                this.$httpApi.get(`/dev-api/authorizedLogin/getQRStatus?qrKey=${this.qrcodeKey}`).then(res => {
                    // console.log('getQrcodeStatus:', res)
                    if (res.code == 200) {
                        this.qrcodeStatus = res.data.qrStatus
                    }

                    if (this.qrcodeStatus === 'Signed') {
                        this.handleLogin(res.data)
                    } else {
                        setTimeout(() => {
                            this.polling()
                        }, 1500)
                    }
                }).catch(err => {
                    console.log('getQrcodeStatus error:', err)
                })
            } else {
                setTimeout(() => {
                    this.polling()
                }, 1500)
            }
        },
        /**
         * @description: 开启轮询
         */
        startPolling() {
            this.isPolling = true
            this.polling()
        },
        /**
         * @description: 停止轮询
         */
        stopPolling() {
            this.isPolling = false
        },
        /**
         * @description: 处理登录逻辑
         * @param {Object} loginInfo 登录返回的信息
         */
        handleLogin(loginInfo) {
            console.log('handleLogin:', loginInfo)

            this.loginInfo = JSON.parse(JSON.stringify(loginInfo))
            this.enterpriseList = loginInfo.userInfo?.topbrass || []
            if (!this.enterpriseList.length) {
                this.$message.error('错误：用户信息缺失，请联系管理员')
                this.refreshQrcode()
                return
            }
            if (this.enterpriseList.length === 1) {
                let token = loginInfo.token
                let sysUser = loginInfo.userInfo?.sysUser
                if (token && sysUser) {
                    sysUser.enterpriseList = this.enterpriseList
                    sysUser.enterpriseInfo = this.enterpriseList[0]
                    sysUser.tyshxydm = this.enterpriseList[0].tyshxydm

                    this.$store.commit('updateToken', token)
                    this.$store.commit("updateUserInfo", sysUser)
                    this.$message.success('登录成功')
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                } else {
                    this.$message.error('错误：用户信息缺失，请联系管理员')
                    this.refreshQrcode()
                    return
                }
            } else {
                this.roleDialogShow = true
            }
        },
        /**
         * @description: 刷新二维码
         */
        refreshQrcode() {
            this.isPolling = false
            this.getQrcode()

            this.$nextTick(() => {
                setTimeout(() => {
                    this.isPolling = true
                    this.polling()
                }, 1500)
            })
        },
        /**
         * @description 选择角色
         * @param {Object} role 角色
         */
        selectRole(role) {
            let token = this.loginInfo.token
            let sysUser = this.loginInfo.userInfo?.sysUser
            if (token && sysUser) {
                sysUser.enterpriseList = this.enterpriseList
                sysUser.enterpriseInfo = role
                sysUser.tyshxydm = role.tyshxydm

                this.$store.commit('updateToken', token)
                this.$store.commit("updateUserInfo", sysUser)
                this.roleDialogShow = false
                this.$message.success('登录成功')
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            } else {
                this.$message.error('错误：用户信息缺失，请联系管理员')
                this.refreshQrcode()
                return
            }
        },
        /**
         * @description: 获取二维码
         */
        getQrcode() {
            let requestMethod = ''
            let url = ''
            let params = {}
            switch (this.qrcodeType) {
                case '1':
                    requestMethod = 'post'
                    url = '/dev-api/authorizedLogin/getAuthorizedWeixinQr'
                    params.path = 'pagesA/applyToManager/face_verify'
                    params.scene = 'oNQIq4_qrvxyk_yRVcOC0uGpX4aw'
                    break
                case '2':
                    requestMethod = 'get'
                    url = '/dev-api/authorizedLogin/getAuthorizedQr'
                    break
                default:
                    break
            }
            this.$httpApi[requestMethod](url, params).then(res => {
                // console.log('getQrcode:', res)
                if (res.code == 200) {
                    this.qrcodeUrl = `data:image/gif;base64,${res.data.QR}`
                    this.qrcodeKey = res.data.qrKey
                    this.qrcodeStatus = res.data.status
                }
            }).catch(err => {
                console.log('getQrcode error:', err)
            })
        },
    },
    mounted() {
        this.getQrcode()
    },
    beforeDestroy() {
        this.stopPolling()
    },
}
</script>

<style lang="scss" scoped>
$codeSize: 200px;

.login-qrcode {
    position: relative;
    width: $codeSize;
    height: $codeSize;
}
.qrcode {
    width: $codeSize;
    height: $codeSize;
}
.wrap-tootip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.tootip-item {
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(#ffffff, 0.8);
}
.tootip-Scanned {
    font-size: 12px;
    font-weight: bold;
    color: #000000;
    img {
        width: 32px;
        height: 32px;
        margin-bottom: 20px;
    }
    p + p {
        margin-top: 10px;
    }
}
.tootip-Signed {
    font-size: 12px;
    font-weight: bold;
    color: $col-green;
}
.tootip-invalid {
    font-weight: bold;
    color: #000000;

    .invalid-refresh {
        display: inline-block;
        border-radius: 32px;
        margin-top: 20px;
        line-height: 32px;
        color: #ffffff;
        background-color: #383d42;
        cursor: pointer;
    }
}
.p-content {
    .p-cell {
        background-color: #1995fe;
        color: #ffffff;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        cursor: pointer;

        .p-position {
            font-size: 13px;
        }
    }
}
</style>