<template>
  <span
    class="tab-bar-item"
    :style="changeStyle"
    @click="itemClick"
    @mouseover="itemHover"
    @mouseout="removeHover"
  >
    <slot name="item-text"></slot>
  </span>
</template>

<script>
export default {
  name: "HeadBarItem",
  props: {
    path: String,
    colors: {
      type: Object,
      default() {
        return { white: "#fff", blue: "#2d81ff", black: "#000" };
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    currentNavIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  computed: {
    isActive() {
      return this.$route.path.includes(this.path);
    },
    //计算属性改变颜色核心
    //过程：如果按钮被点击了，则为用户传入的颜色，否则在判断鼠标是否移入改变了isHover，移入则变色，否则为默认值
    changeStyle() {
      if (this.$route.path === "/home") {
        let cc = this.colors.white;
        if (this.index == this.currentNavIndex || this.isHover) {
          return {
            color: cc,
            borderBottom: `4px solid ${cc}`,
          };
        } else {
          return { color: cc };
        }
      } else {
        if (this.index == this.currentNavIndex || this.isHover) {
          return {
            color: this.colors.blue,
            borderBottom: `4px solid ${this.colors.blue}`,
          };
        } else {
          return { color: this.colors.black };
        }
      }
    },
  },
  methods: {
    itemClick() {
      //点击实现路由跳转
      this.$router.replace(this.path);
    },
    itemHover() {
      this.isHover = true;
    },
    removeHover() {
      this.isHover = false;
    },
  },
};
</script>
<style scoped>
.tab-bar-item {
  margin: 0 24px;
  padding: 8px 0;
  font-size: 18px;
  font-weight: 500;
}
</style>
