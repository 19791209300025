import store from '../store';
import httpApi from '@/utils/http';

/**
 * 登录校验 tickeCallback
 * @param  callback
 */
let firewallList = ["/login", "/sslogin", "/ssologin","/code"]
let enterpriseList;
const loginAuth = callback => {

  const path = window.location.pathname
  let code= getUrlParam('code');
  if (!process.env.VUE_APP_OPEN_AUTH) {
    callback()
    return
  }
  // 进行访问页面判断
  if (!firewallList.includes(path) && !code) {
    callback()
    return
  } else if (path == '/sslogin') {
    window.location.href = process.env.VUE_APP_GET_CODE
    return
  } else if (!!code&& path == '/code') {
    let code = getUrlParam('code').substr(0, 6)
    let params = {
      code: code
    };
    httpApi.post('/dev-api/lhsso/getLoginParams', params).then((multiRole) => {
      if(multiRole.code == 401){
        alert("该用户为个人用户，没有统一社会信用代码！");
        callback()
        return
      }else{
        if (!multiRole.topbrasses.length) {
          return
        }
        enterpriseList = multiRole.topbrasses
        if (enterpriseList.length === 1) {
          sessionStorage.setItem('isFirst',multiRole.isFirst);
          sessionStorage.setItem('loginSign','province');
          login(enterpriseList[0])
        } else {
          alert("登录报错啦，请联系管理员！")
        }
      }
    })
  } else if (!!code&& path == '/returnCode') {
    code = code.substr(0, 96)
    let param = {
      "code": code
    };
    httpApi.post('/dev-api/deyangsso/getLoginParams', param).then((multiRole) => {
      if(multiRole.code == 401){
        alert("该用户为个人用户，没有统一社会信用代码！");
        callback()
        return
      }else{
        if (!multiRole.topbrasses.length) {
          return
        }
        enterpriseList = multiRole.topbrasses
        if (enterpriseList.length === 1) {
          sessionStorage.setItem('isFirst',multiRole.isFirst);
          sessionStorage.setItem('loginSign','city');
          login(enterpriseList[0])
        } else {
          alert("登录报错啦，请联系管理员！")
        }
      }
    })
  }else {
    window.location.href = process.env.VUE_APP_LOGIN_URL
  }
}

/**
 * @description 登录
 * @param {Object} role 角色信息
 */
function login(role) {
  let params = {
    phone: role.phone,
    userId: role.userId,
  }
  httpApi.post('/dev-api/sms/login', params).then(res => {
    if (res.code == 200) {
      if (res.userInfo) {
        let temp_userInfo = JSON.parse(JSON.stringify(res.userInfo.sysUser))
        temp_userInfo.enterpriseList = enterpriseList
        temp_userInfo.enterpriseInfo = JSON.parse(JSON.stringify(role))
        temp_userInfo.tyshxydm = role.tyshxydm
        temp_userInfo.enterpriseInfo.logoId = res.userInfo.user?.logo
        sessionStorage.setItem('titleType', 1)
        store.commit('updateToken', res.token)
        store.commit("updateUserInfo", temp_userInfo)
        sessionStorage.setItem("titleType", 1);
        window.location.href = '/'
        return
      }
    }
  }).catch(err => {
    console.log('login error:', err)
  })
}


function getUrlParam(paraName) {
  let url = document.location.toString()
  if (url.indexOf("?") > -1) {
    let arrObj = url.split('?')
    let arrPara = arrObj[1].split('&')
    let arr
    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=')
      if (arr != null && arr[0] == paraName) {
        return arr[1]
      }
    }
  }
  return ''
}

const SSO = {
  loginAuth: loginAuth
}

export default SSO
