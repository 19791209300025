<template>
  <div class="login-container">
    <img
      class="login-system-name"
      :src="require('@/assets/images/loginTitle.png')"
      alt=""
    />
    <div class="wrap-thumbnail">
      <img :src="require('@/assets/images/login_smImg.png')" />
      <div class="pad-1 bord-r-2 thumbnail-content">
        <p>办事像网购一样方便快捷</p>
        <p>惠企政策像快递一样精准直达</p>
      </div>
    </div>
    <div class="login-content">
      <div class="login-right">
        <div class="form-content" ref="loginContainer">
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            autocomplete="on"
            label-position="left"
          >
            <h2 class="form-title">欢迎登录</h2>
            <div
              class="code-login"
              v-if="activeType == 1 && isCodeLogin && isShowWxQrCode"
            >
              <div class="qr-code-box">
                <el-image class="qr-code-img" :src="wxQrCodeUrl">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
                <div class="invalid-mask" v-if="qrCodeStatus == 'invalid'">
                  二维码已失效
                  <div class="shuaxin" @click="refreshWxCode">刷新</div>
                </div>
                <div
                  class="invalid-mask"
                  v-if="qrCodeStatus == 'Scanned' || qrCodeStatus == 'Signed'"
                >
                  <img
                    class="tip-img"
                    src="../../assets/images/icon_scan_success.png"
                    alt=""
                  />
                  <p class="tip-success">扫码成功!</p>
                  <p class="tip-info">请在手机上确认登录</p>
                </div>
              </div>

              <p class="tip-top">请打开微信App</p>
              <p class="tip-bottom">
                使用<span class="tip-red">「扫一扫」</span>扫描
              </p>
            </div>

            <div
              class="code-login"
              v-else-if="activeType == 1 && isCodeLogin && !isShowWxQrCode"
            >
              <div class="qr-code-box">
                <el-image class="qr-code-img" :src="qrCodeUrl">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
                <div class="invalid-mask" v-if="qrCodeStatus == 'invalid'">
                  二维码已失效
                  <div class="shuaxin" @click="refreshCode">刷新</div>
                </div>
                <div
                  class="invalid-mask"
                  v-if="qrCodeStatus == 'Scanned' || qrCodeStatus == 'Signed'"
                >
                  <img
                    class="tip-img"
                    src="../../assets/images/icon_scan_success.png"
                    alt=""
                  />
                  <p class="tip-success">扫码成功!</p>
                  <p class="tip-info">请在手机上确认登录</p>
                </div>
              </div>
              <p class="tip-top">
                请打开<span :style="{ color: variableStyle['color-theme'] }"
                  >微信小程序</span
                >进行扫码登录
              </p>
            </div>
            <div class="input-login" v-else>
              <el-form-item prop="phone">
                <el-input
                  ref="phone"
                  v-model.number="loginForm.phone"
                  placeholder="请输入您的手机号码"
                  name="phone"
                  tabindex="1"
                  autocomplete="on"
                  prefix-icon="el-icon-user"
                />
              </el-form-item>
              <el-form-item prop="captchaCode" class="code-box">
                <div class="login-code-container flex-row-between">
                  <div class="login-code-ipt">
                    <el-input
                      v-model="loginForm.captchaCode"
                      auto-complete="off"
                      placeholder="验证码"
                      @keyup.enter.native="handleLogin"
                    >
                    </el-input>
                  </div>
                  <img
                    :src="codeUrl"
                    @click="getCodeImg"
                    class="login-code-img"
                  />
                </div>
              </el-form-item>
              <el-form-item prop="code" class="code-box">
                <div class="flex-row-between">
                  <div class="login-code-ipt">
                    <el-input
                      v-model="loginForm.code"
                      auto-complete="off"
                      placeholder="请输入短信验证码"
                      maxlength="6"
                      @keyup.enter.native="handleLogin"
                    >
                    </el-input>
                  </div>
                  <el-button
                    class="login-code-btn"
                    type="primary"
                    :disabled="!!msgCodeInfo.timer"
                    @click="getSmsCode"
                  >
                    {{
                      msgCodeInfo.timer
                        ? `剩余${msgCodeInfo.second}秒`
                        : "获取短信验证码"
                    }}
                  </el-button>
                </div>
              </el-form-item>

              <el-button
                :loading="loading"
                type="primary"
                class="login-button"
                @click.native.prevent="handleLogin"
                >登 录
              </el-button>
              <!-- <div class="el-row--flex">
                                <div class="btn-btm">
                                    <a
                                        class="pointer"
                                        @click="toRegister"
                                    >立即注册</a>
                                </div>

                                <div class="btn-btm">
                                    <a
                                        class="pointer"
                                        @click="retrievePwd"
                                    >找回密码</a>
                                </div>
                            </div> -->
            </div>

            <!-- <div
                            class="code-login-img"
                            @click="toggleCodeLogin"
                            v-show="activeType == 1"
                        >
                            <img
                                v-if="isCodeLogin"
                                class="code-img"
                                src="../../assets/images/pcLogin.png"
                                alt=""
                            />
                            <img
                                v-else
                                class="code-img"
                                src="../../assets/images/erweima.png"
                                alt=""
                            />
                        </div> -->
          </el-form>
        </div>
      </div>
    </div>

    <el-dialog
      v-loading="roleDialogLoading"
      title="请选择您要登录的企业"
      :visible.sync="dialogVisible"
      width="25%"
      :show-close="false"
      :center="true"
    >
      <div class="p-content">
        <div
          v-for="item in enterpriseList"
          :key="item.id"
          @click="selectRole(item)"
          class="p-cell"
        >
          <div class="">
            {{ item.enterprise }}
          </div>
          <div class="p-position">
            职位：{{
              item.positionName && item.positionName !== "null"
                ? item.positionName
                : ""
            }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkDataType } from "@/utils/index.js";
import variableStyle from "@/styles/variable.scss";

export default {
  name: "Login",
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
    }),
  },
  data() {
    return {
      variableStyle: variableStyle,
      /* 切换 */
      activeType: "1",
      codeUrl: "", // 手机号登录时的图形验证码
      isCodeLogin: false, // 个人账号时 是否扫码登录,true则显示二维码或小程序码false
      isShowWxQrCode: true, // true则显示小程序码 false则显示二维码，上线后应该不要二维码了
      // 二维码登录的
      qrCodeUrl: "", // 二维码URL
      qrCodeStatus: "", // 已生成 generated 已扫描 Scanned  已登录 Signed  已作废 invalid
      qrKey: "", // 用这个key来轮询
      // 小程序码登录
      wxQrCodeUrl: "",

      loginForm: {
        // phone: "13798580884",
        phone: "18805282227",
        code: "654321",
        uuid: "", // 图形验证码UUID
        captchaCode: "", // 图形验证码
      },
      loginRules: {
        phone: [
          { required: true, trigger: "blur", message: "请输入您的手机号码" },
        ],
        captchaCode: [
          { required: true, trigger: "blur", message: "请输入图形验证码" },
        ],
        code: [
          { required: true, trigger: "blur", message: "请输入短信验证码" },
        ],
      },
      loading: false,
      enterpriseList: [], // 是否有多家企业
      dialogVisible: false, // 弹窗选择哪一家企业来登录
      roleDialogLoading: false, // 多角色弹窗loading状态位
      // 短信验证码
      msgCodeInfo: {
        timer: null, // 计时器
        second: 60, // 计时时间
      },
      qrLoginInfo: {}, // 扫码登录返回的信息
    };
  },
  methods: {
    toggleCodeLogin() {
      this.isCodeLogin = !this.isCodeLogin;
      if (this.isCodeLogin) {
        this.getQrCode();
        this.openInterval();
      } else {
        this.clrInterval();
      }
    },
    refreshCode() {
      // 刷新二维码
      this.qrCodeStatus = "";
      this.getQrCode();
      this.openInterval();
    },
    refreshWxCode() {
      // 刷新小程序码
      this.wxQrCodeStatus = "";
      this.getWxQrCode();
      this.openInterval();
    },
    clrInterval() {
      // 清除定时器
      if (this.qrInterval) {
        clearInterval(this.qrInterval);
        this.qrInterval = null;
      }
    },
    openInterval() {
      // 打开定时器获取状态
      this.clrInterval();
      if (!this.isCodeLogin) {
        return;
      }
      this.qrInterval = setInterval(() => {
        this.getQRStatus();
      }, 1500);
    },
    handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          // this.loading = true;
          let params = {
            phone: this.loginForm.phone,
            verCode: this.loginForm.code,
          };
          /**
           * 20210621
           * 原先的多角色信息，是放在登录接口返回的数据里
           * 现在把这块抽出，用一个单独的接口，来获取用户的多角色信息
           * 针对性的补充逻辑
           */
          let multiRole = await this.$httpApi.post(
            "/dev-api/sms/getLoginEnterprise",
            params
          );
          // console.log('multiRole:', multiRole)
          if (multiRole.code == 200) {
            if (!multiRole.topbrasses.length) {
              this.$message.error("错误：用户信息缺失，请联系管理员");
              // this.loading = false
              return;
            }

            this.enterpriseList = multiRole.topbrasses;
            if (this.enterpriseList.length === 1) {
              this.login(this.enterpriseList[0]);
            } else {
              this.dialogVisible = true;
            }
          } else {
            this.$message.error(`错误：${multiRole.msg}`);
            // this.loading = false
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * @description 登录
     * @param {Object} role 角色信息
     */
    login(role) {
      let params = {
        phone: this.loginForm.phone,
        userId: role.userId,
      };
      this.$httpApi
        .post("/dev-api/sms/login", params)
        .then((res) => {
          // console.log('login:', res)
          // this.loading = false
          this.roleDialogLoading = false;
          if (res.code == 200) {
            if (res.userInfo) {
              let temp_userInfo = JSON.parse(
                JSON.stringify(res.userInfo.sysUser)
              );
              temp_userInfo.enterpriseList = this.enterpriseList;
              temp_userInfo.enterpriseInfo = JSON.parse(JSON.stringify(role));
              temp_userInfo.tyshxydm = role.tyshxydm;
              temp_userInfo.enterpriseInfo.logoId = res.userInfo.user?.logo;
              //sessionStorage.setItem("titleType", 1);

              this.$store.commit("updateToken", res.token);
              this.$store.commit("updateUserInfo", temp_userInfo);

              this.jumpToPage();
            } else {
              this.$message.error("错误：用户信息缺失，请联系管理员");
            }
          }
        })
        .catch((err) => {
          console.log("login error:", err);
          // this.loading = false
          this.roleDialogLoading = false;
          this.$message.error("错误：", err);
        });
    },
    /**
     * @description 选择角色
     * @param {Object} role 角色信息
     */
    selectRole(role) {
      /**
       * 20210628
       * 区分 扫码登录/账号登录
       * 扫码登录，选择后直接保存信息
       * 账号登录，选择后还需调用登录接口
       */
      if (this.activeType == 1) {
        let token = this.qrLoginInfo.token;
        let sysUser = this.qrLoginInfo.userInfo?.sysUser;
        if (token && sysUser) {
          sysUser.enterpriseList = this.enterpriseList;
          sysUser.enterpriseInfo = role;
          sysUser.tyshxydm = role.tyshxydm;

          this.$store.commit("updateToken", token);
          this.$store.commit("updateUserInfo", sysUser);
          this.jumpToPage();
        } else {
          this.$message.error("错误：用户信息缺失，请联系管理员");
          return;
        }
      } else {
        this.roleDialogLoading = true;
        this.login(role);
      }
    },

    jumpToPage() {
      this.dialogVisible = false;
      this.$message({
        message: "登录成功",
        type: "success",
      });
      setTimeout(() => {
        this.loading = false;
        let targetStr = this.$router.history.current.path;
        if (["/register", "/login"].indexOf(targetStr) !== -1) {
          /**
           * 20210527
           * 配合小程序端复制链接直接进入政策申报页面
           * 如果未登录且重定向到登录页时，登录后应该直接返回
           */
          if (this.$route.query.back) {
            this.$router.replace(this.$route.query.back);
          } else {
            this.$router.replace({
              path: "/home",
            });
          }
        } else {
          this.$router.go(-1);
        }
      }, 1000);
    },
    getUserInfo() {
      this.$httpApi
        .get(
          "/dev-api/getInfo" +
            "?appid=afc903d9d5e449488a3cf9a00c3d1294&appkey=8cb4f61b-e89a-405b-acc8-4d992826c247",
          {}
        )
        .then((res) => {
          // console.log("信息getInfo: ", res);
          if (res.code === 200 && res.data) {
            let baseInfo = res.data.baseInfo;
            if (baseInfo != null) {
              this.$store.commit("updateAccountBaseInfo", baseInfo);
            }

            let userInfo = res.data.userInfo || {};
            /**
             * 20210506
             * 元林优化了 账号密码登录后这个接口返回的用户信息
             * 补了最关键的 userId用户ID 和 tyshxydm统一社会信用代码
             * 参考 扫码登录后整理的数据格式，这边也统一处理
             */
            userInfo.topbrass = res.data.topbrass || {};
            userInfo.userId = userInfo.topbrass.userId; // 用户ID
            userInfo.username = userInfo.loginId; // 用户账号(手机号码)
            userInfo.nickName = userInfo.name; // 用户昵称
            userInfo.sex = userInfo.topbrass.sex; // 性别
            userInfo.tyshxydm = userInfo.topbrass.tyshxydm; // 统一社会信用代码

            // console.log("信息userInfo", userInfo);
            // 判断是哪种类型账号 （公司账号信息必有社会信用代码，以此为判断条件）
            // if (userInfo.shxydm) {
            //   this.userType = 0;
            // } else {
            //   this.userType = 1;
            // }
            let mid = {
              icon: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
              userStatus: "已经绑定账号", // 用户是否绑定账号状态
              emailStatus: "未绑定邮箱", // 邮箱状态
              security: "1", // 安全等级 0 1 2
            };
            Object.assign(userInfo, mid);
            // userInfo.shxydm = baseInfo.shxydm;
            this.$store.commit("updateUserInfo", userInfo);
          }
        })
        .catch((err) => {});
    },
    getSmsCode() {
      if (!this.loginForm.phone) {
        this.$message("请先填写手机号");
        return;
      }

      let reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg.test(this.loginForm.phone)) {
        this.$message("请输入正确的手机号");
        return;
      }
      /**
       * 20210601
       * 获取短信验证码时，把图形验证码的信息带上
       */
      if (!this.loginForm.uuid) {
        this.$message("错误，无效的图形验证码，请刷新");
        return;
      }
      if (!this.loginForm.captchaCode) {
        this.$message("请先填写图形验证码");
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: "正在获取验证码",
        spinner: "el-icon-loading",
        target: this.$refs.loginContainer,
      });
      this.$httpApi
        .get("/dev-api/sms/getVerCode", {
          phone: this.loginForm.phone,
          uuid: this.loginForm.uuid,
          captchaCode: this.loginForm.captchaCode,
        })
        .then((res) => {
          this.$nextTick(() => {
            loading.close();
          });
          if (res.code == 200) {
            this.$message.success("验证码已发送");
            this.msgCodeInfo.timer = setInterval(() => {
              if (--this.msgCodeInfo.second === 0) {
                clearInterval(this.msgCodeInfo.timer);
                this.msgCodeInfo.timer = null;
                this.msgCodeInfo.second = 60;
              }
            }, 1000);
          } else {
            this.$message.error(`错误：${res.msg}`);
          }
        })
        .catch((err) => {
          console.log("Err:", err);
          this.$message("网络异常，请稍后重试");
          this.$nextTick(() => {
            loading.close();
          });
        });
    },
    /**
     * @description 获取图形验证码
     * 获取验证码的方法
     */
    getCodeImg() {
      this.$httpApi
        .get("/dev-api/captchaImage", {})
        .then((res) => {
          console.log("graphCode:", res);
          if (res.code === 200) {
            this.codeUrl = "data:image/gif;base64," + res.img;
            this.loginForm.uuid = res.uuid;
          }
        })
        .catch((err) => {});
    },
    getQrCode() {
      if (this.isShowWxQrCode) {
        // 获取小程序码
        this.$httpApi
          .post("/dev-api/authorizedLogin/getAuthorizedWeixinQr", {
            path: "pagesA/applyToManager/face_verify", //扫小程序码后跳转到小程序到这个face_verify页面
            scene: "oNQIq4_qrvxyk_yRVcOC0uGpX4aw", // 可以是传到小程序到参数，看需要传什么数据到小程序就用json字符串传
          })
          .then((res) => {
            console.log("getAuthorizedWeixinQr", res);
            if (res.code === 200) {
              this.wxQrCodeUrl = "data:image/gif;base64," + res.data.QR;
              this.qrKey = res.data.qrKey;
              console.log("获取的qrKey:", this.qrKey);
            }
          })
          .catch((err) => {});
      } else {
        // 获取二维码
        this.$httpApi
          .get("/dev-api/authorizedLogin/getAuthorizedQr", {})
          .then((res) => {
            console.log("getQrCode", res);
            if (res.code === 200) {
              this.qrCodeUrl = "data:image/gif;base64," + res.data.QR;
              this.qrKey = res.data.qrKey;
            }
          })
          .catch((err) => {});
      }
    },
    getQRStatus() {
      this.$httpApi
        .get(`/dev-api/authorizedLogin/getQRStatus?qrKey=${this.qrKey}`)
        .then((res) => {
          // 不停的去获取二维码状态，枚举值 // 已生成 generated 已扫描 Scanned  已登录 Signed
          // 当不是generated的时候说明已经失效了 需要重新生成
          console.log("==> getQRStatus", res);
          if (res.code === 200) {
            this.qrCodeStatus = res.data.qrStatus;
            if (this.qrCodeStatus == "Signed") {
              this.clrInterval();
              this.$message({
                message: "登录成功",
                type: "success",
              });
              /**
               * 20210513
               * 扫码登录的话，用户信息直接在扫码返回的信息中获取
               * 暂不再去调用 getUserInfo
               */
              this.qrLoginInfo = JSON.parse(JSON.stringify(res.data));
              this.enterpriseList = this.qrLoginInfo.userInfo?.topbrass || [];
              if (!this.enterpriseList.length) {
                this.$message.error("错误：用户信息缺失，请联系管理员");
                return;
              }
              if (this.enterpriseList.length === 1) {
                let token = this.qrLoginInfo.token;
                let sysUser = this.qrLoginInfo.userInfo?.sysUser;
                if (token && sysUser) {
                  sysUser.enterpriseList = this.enterpriseList;
                  sysUser.enterpriseInfo = this.enterpriseList[0];
                  sysUser.tyshxydm = this.enterpriseList[0].tyshxydm;

                  this.$store.commit("updateToken", token);
                  this.$store.commit("updateUserInfo", sysUser);
                  this.jumpToPage();
                } else {
                  this.$message.error("错误：用户信息缺失，请联系管理员");
                  return;
                }
              } else {
                this.dialogVisible = true;
              }
              let token = res.data.token;
              let userInfo = res.data.userInfo || {};
              let sysUser = userInfo.sysUser || {};
              sysUser.enterpriseInfo = userInfo.topbrass;
              if (sysUser.enterpriseInfo) {
                sysUser.tyshxydm = sysUser.enterpriseInfo.tyshxydm;
              }
              if (token) {
                this.$store.commit("updateToken", token);
              }
              this.$store.commit("updateUserInfo", sysUser);
              // this.getUserInfo()

              setTimeout(() => {
                this.loading = false;
                let targetStr = this.$router.history.current.path;
                if (["/register", "/login"].indexOf(targetStr) !== -1) {
                  /**
                   * 20210527
                   * 配合小程序端复制链接直接进入政策申报页面
                   * 如果未登录且重定向到登录页时，登录后应该直接返回
                   */
                  if (this.$route.query.back) {
                    this.$router.replace(this.$route.query.back);
                  } else {
                    this.$router.replace({
                      path: "/home",
                    });
                  }
                } else {
                  this.$router.go(-1);
                }
              }, 1000);
            }
          } else {
            this.clrInterval();
          }
        })
        .catch((err) => {
          this.clrInterval();
        });
    },
    toRegister() {
      this.$router.replace({
        path: "/register",
      });
    },
    /**
     * 切换状态，修改status 值
     */
    // activeClick(tab, event) {
    //     console.log(this.activeType);
    //     this.loginForm.status = tab.name;
    //     if (this.activeType == 0) {
    //         this.clrInterval();
    //     } else if (this.isCodeLogin) {
    //         this.openInterval();
    //     }
    // },
    /**
     * 找回密码
     */
    retrievePwd() {
      this.$router.push({
        path: "/findPwd?activeType=" + this.activeType,
      });
    },
  },
  created() {
    if (this.$route.query.user) {
      this.loginForm.phone = this.$route.query.user;
    }
    this.getCodeImg();
  },
  mounted() {
    if (this.loginForm.phone === "") {
      this.$refs.phone.focus();
    }
    /**
     * 20210524
     * 优先显示扫码登录，且配合 首页二维码登录快捷入口
     * 页面挂载完，再启动定时器
     */
    if (this.isCodeLogin) {
      this.getQrCode();
      this.openInterval();
    } else {
      this.clrInterval();
    }
  },
  beforeDestroy() {
    this.clrInterval();
    clearInterval(this.msgCodeInfo.timer);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

.login-container {
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/login_banner.png") center / 100% 100%
    no-repeat;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  overflow: hidden;
}
.login-system-name {
  position: absolute;
  top: 10vh;
  left: 50%;
  height: 60px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.wrap-thumbnail,
.login-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-30%);
  -moz-transform: translateY(-30%);
  -o-transform: translateY(-30%);
  -ms-transform: translateY(-30%);
  transform: translateY(-30%);
}
.wrap-thumbnail {
  right: 50%;
  height: 400px;

  img {
    height: 100%;
  }
  .thumbnail-content {
    position: absolute;
    top: -20px;
    right: 10%;
    width: max-content;
    color: #ffffff;
    font-weight: bold;
    background-color: #1292ff;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      border-top: 5px solid transparent;
      border-right: 8px solid #1292ff;
      border-bottom: 5px solid transparent;
      -webkit-transform: translate(-100%, -50%);
      -moz-transform: translate(-100%, -50%);
      -o-transform: translate(-100%, -50%);
      -ms-transform: translate(-100%, -50%);
      transform: translate(-100%, -50%);
    }
  }
}

.login-content {
  left: 50%;
}

.form-content {
  width: 420px;
  height: 400px;
  padding: 40px 50px;
  /*/ / border: 1 px solid #333;*/
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 3px 15px rgba($color: rgb(0, 0, 0), $alpha: 0.2);
  position: relative;

  // span {
  //   font-size: 16px;
  //   font-weight: 600;
  // }

  .el-form {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;

    .el-form-item {
      width: 100%;

      .el-form-item__content {
        height: 70px;
      }
    }
    .code-box {
      /*/ / background: red;*/
      width: 100%;

      .el-form-item__content {
        width: 100%;
        height: 100%;

        .login-code-ipt {
        }
        .login-code-img {
          width: 100px;
          height: 42px;
          cursor: pointer;
        }
      }
      .login-code-btn {
        margin-left: 4px;
      }
    }
  }
  .el-btn-ctr,
  .to-login {
    width: 100%;
    padding: 0 40px;
    margin-top: 10px;
  }

  .el-button {
    width: 100%;
  }

  .to-login {
    margin-top: 10px;
    position: relative;

    a {
      position: absolute;
      right: 40px;
    }
  }
  .btn-btm {
    width: 64px;
    height: 21px;
    margin-top: 20px;
    margin-right: 20px;
    font-size: 16px;
    text-align: left;

    a {
      color: #999999;
    }
  }

  .code-login-img {
    position: absolute;
    right: 1px;
    bottom: 1px;
    width: 60px;
    height: 60px;
    .code-img {
      width: 100%;
      height: 100%;
    }
  }
  .code-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    // height: 100%;
    text-align: center;
    .qr-code-box {
      width: 200px;
      height: 200px;
      margin-bottom: 20px;
      position: relative;
      .qr-code-img {
        width: 100%;
        height: 100%;
        background: #efefef;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .invalid-mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($color: #fff, $alpha: 0.8);
        color: #000;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .shuaxin {
          width: 62px;
          height: 35px;
          line-height: 35px;
          border-radius: 50px;
          background-color: #383d42;
          color: #fff !important;
          margin-top: 16px;
        }
        .tip-img {
          width: 34px;
          height: 34px;
        }
        .tip-success {
          color: #000;
          font-size: 12px;
          margin-top: 24px;
          margin-bottom: 10px;
        }
        .tip-info {
          color: #000;
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
    .tip-top {
      font-size: 14px;
      color: #222;
    }
    .tip-bottom {
      font-size: 12px;
      color: #555;
    }
    .tip-red {
      color: #f00;
    }
  }
}
.form-title {
  width: 100%;
  text-align: center;
  // color: $col-theme;
  color: #0083ff;
  letter-spacing: 10px;
}
.input-login {
  width: 100%;
  min-height: 200px;

  .login-button {
    width: 100%;
    height: 50px;
    font-family: Microsoft YaHei;
    font-weight: 700;
    color: #ffffff;
    font-size: 20px;
    // background-color: $col-theme;
    background-color: #0083ff;
    // margin-top: 30px;
  }
}
.p-content {
  padding: 30upx;

  .p-cell {
    background-color: #1995fe;
    color: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    cursor: pointer;

    .p-position {
      font-size: 13px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .wrap-thumbnail {
    right: 55%;
  }
  .login-content {
    left: 55%;
  }
}
::v-deep {
  .el-button:not([disabled]).el-button--primary,
  .el-button:not([disabled]).el-button--primary:hover {
    border-color: #0083ff;
    background-color: #0083ff;
  }
}
</style>
