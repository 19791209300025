<!--
 * @Author: LXG
 * @Date: 2021-05-12
 * @Editors: LXG
 * @LastEditTime: 2021-07-16 16:00:35
 * @Description: layout - header
-->
<template>
  <div>
    <div class="header">
      <div class="def-container header-inner">
        <div class="header-logo">
          <img
            src="@/assets/deyangImg/sczwfwBanner.png"
            alt=""
            @click="toZwfw"
          />
          <el-divider direction="vertical"></el-divider>
          <span @click="toHome">德阳市惠企政策通</span>
        </div>
        <!-- <a @click="toEarly">汇报版本</a> -->
        <div class="header_right">
          <!-- 未登录 -->
          <a
            href="http://gjzwfw.www.gov.cn/"
            ><span class="header_right_txt">国家政务服务平台</span></a
          >
          <div class="divider"></div>
          <a href="http://www.sc.gov.cn/"
            ><span class="header_right_txt">四川省人民政府网</span></a
          >
          <div v-if="!userInfo" class="notLogin">
<!--            <div
              class="pad-rl-1 pointer notLogin-login"
              v-popover:qrPopup
              @click="toLogin"
            >
              <TjIcon name="user"></TjIcon>
              <span>登录</span>
            </div>-->
<!--            <div
              class="pad-rl-1 pointer notLogin-login"
              v-popover:qrPopup
              @click="toCityLogin"
            >
              <TjIcon name="user"></TjIcon>
              <span>市登录</span>
            </div>-->
<!--            <span>/</span>
            <div class="pad-rl-1 pointer notLogin-register" @click="toRegister">
              <TjIcon name="register"></TjIcon>
              <span>注册</span>
            </div>-->
            <el-popover
             class="loginPopover"
              placement="top-start"
              width="200"
              trigger="hover">
              <el-row>
                <el-col :span="7">
                  <div class="grid-content bg-purple"><img
                    src="@/assets/images/home/deyangzwfw.png"
                    alt=""
                    @click="toCityLogin"
                  /></div>
                </el-col>
                <el-col :span="15" style="text-align:center">
                  <el-row><div class="grid-content bg-purple">德阳智慧城市</div></el-row>
                  <el-row style="margin-top:-8px"><el-button type="text" @click="toCityLogin"><TjIcon name="user"></TjIcon> 登录</el-button></el-row>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="7">
                  <div class="grid-content bg-purple"><img
                    src="@/assets/images/home/scszwfw.png"
                    alt=""
                    @click="toLogin"
                  /></div>
                </el-col>
                <el-col :span="15" style="text-align:center">
                  <el-row><div class="grid-content bg-purple">四川政务服务网</div></el-row>
                  <el-row style="margin-top:-8px"><el-button type="text" @click="toLogin"><TjIcon name="user"></TjIcon> 登录</el-button></el-row>
                </el-col>
              </el-row>
              <el-button round size="mini" slot="reference"><TjIcon name="user"></TjIcon> 登录</el-button>
            </el-popover>
          </div>
          <!-- 已登录 -->
          <div v-else class="flex-row-between login-register1">
            <div
              class="login-icon flex-row pointer"
              @click="toWork"
              v-popover:popover1
            >
              <i class="el-icon-user-solid"></i>
              <span
                class="max-one-line ui-name"
                style="
                  max-width: 300px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ showUserName }}
              </span>
            </div>
            <span class="marg-rl-1 u-sperator">|</span>
            <!-- 铃铛加上类名el-icon-message-solid -->
            <div
              class="register-cion flex-row pointer"
              @click="toWork"
              v-popover:popoverMessage
            ></div>
            <el-popover
              ref="popover1"
              placement="bottom"
              width="180"
              trigger="hover"
            >
              <span slot="reference" @click="toWork">用户中心</span>
              <div class="flex-column-between loginInfo">
                <div
                  class="login-cell pointer"
                  v-for="(item, index) in loginList"
                  :key="index"
                  @click="gotoItem(item.path)"
                >
                  <i class="cell-icon" :class="item.icon"></i>
                  <div class="login-name">{{ item.name }}</div>
                </div>
              </div>
            </el-popover>
            <el-popover
              ref="popoverMessage"
              placement="bottom"
              width="300"
              trigger="hover"
            >
              <div class="flex-column-between messageInfo pointer">
                <ul class="nav-wrap">
                  <li
                    class="message-titleList"
                    :class="{
                      'message-titleList--active':
                        msTitleCurrentNav === msIndex,
                    }"
                    v-for="(el, msIndex) in msTitleList"
                    :key="msIndex"
                    @click="changemsNav(msIndex)"
                  >
                    {{ el.name }}（{{ getLength(msIndex) }}）
                  </li>
                </ul>
                <!-- 通知列表 -->
                <div class="message-list">
                  <div
                    class="message-cell pointer"
                    v-for="(item, index) in cpu_messageList"
                    :key="index"
                    @click="gotoMessage(item)"
                  >
                    <div class="message-name">{{ item.title }}</div>
                    <div class="message-time">{{ item.createTime }}</div>
                  </div>
                  <!-- 暂无数据 -->
                  <NoDataView
                    v-show="!cpu_messageList.length"
                    dataState="noData"
                  ></NoDataView>
                </div>
                <!--查看更富多  -->
                <div class="message-showMore pointer" @click="gotoMyMessage">
                  查看更多
                </div>
              </div>
            </el-popover>
          </div>
          <a href="javascript:void(0)" @click="showUserManual">用户手册</a>
        </div>
      </div>
    </div>
    <div class="def-container home-title">
      <div class="home-title_1">
        <div
          class="home-title_2"
          @click="check(1)"
        >
          首页
        </div>
        <div
          class="home-title_2"
          @click="check(2)"
        >
          个人服务
        </div>
        <div
          class="home-title_2"
          @click="check(3)"
        >
          法人服务
        </div>
        <div
          class="home-title_2"
          @click="check(4)"
        >
          直通部门
        </div>
        <div
          class="home-title_2"
          @click="check(5)"
        >
          直通市州
        </div>
        <div
          class="home-title_2"
          @click="check(6)"
        >
          政民互动
        </div>
        <div
          class="home-title_2"
          @click="check(7)"
        >
          政务公开
        </div>
        <div
          class="home-title_2"
          @click="check(8)"
        >
          主题服务
        </div>
        <div
          class="home-title_2"
          @click="check(9)"
        >
          川渝通办
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TjIcon from "@/components/tjIcon/TjIcon";
import LoginQrcode from "@/components/loginQrcode/LoginQrcode";
import NoDataView from "@/components/nodata-view";
import { LOGIN_URL, LOGIN_URL_BACK } from "@/store/mutation-types";

const LOGO_SRC = require("@/assets/images/header-logo.png");

export default {
  name: "Header",
  components: {
    TjIcon,
    //LoginQrcode,
    NoDataView,
  },
  data() {
    return {
      showUserName: "",
      loginList: [
        {
          name: "用户中心",
          icon: "el-icon-user",
          path: "/workplace/index",
        },
        {
          name: "账户管理",
          icon: "el-icon-setting",
          path: "/workplace/setting",
        },
        {
          name: "退出",
          icon: "el-icon-switch-button",
          path: "/logout",
        },
      ],
      msTitleList: [
        { name: "通知", path: "" },
        { name: "公告", path: "" },
        { name: "订阅", path: "" },
      ],
      msTitleCurrentNav: 0,
      tongzhiList: [], // 通知消息
      tongzhiTotal: 0, // 通知消息总数
      gonggaoList: [], // 公告消息
      gonggaoTotal: 0, // 公告总数
      dingyueList: [], // 订阅消息
      dingyueTotal: 0, // 订阅总数
      // titleType: '',
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
    }),
    cpu_logoSrc() {
      return LOGO_SRC;
    },
    cpu_messageList() {
      switch (this.msTitleCurrentNav) {
        case 0:
          return this.tongzhiList;
        case 1:
          return this.gonggaoList;
        case 2:
          return this.dingyueList;
        default:
          return [];
      }
    },
  },
  methods: {
    /**
     * @description 导航切换
     */
    check(i) {
      let indexkey = i;
      let href = "";
      switch (indexkey) {
        case 1:
          // this.$router.push({
          //   name: "homePage",
          // });
          href = "http://dys.sczwfw.gov.cn/index.html?areaCode=510600000000"
          break;
        case 2:
          href = "http://www.sczwfw.gov.cn/jiq/front/item/gr_index?areaCode=510600000000"
          break;
        case 3:
          href = "http://www.sczwfw.gov.cn/jiq/front/item/fr_index?areaCode=510600000000"
          break;
        case 4:
          href = "http://www.sczwfw.gov.cn/jiq/front/channel/deptSwitch?areaCode=510600000000"
          break;
        case 5:
          href = "http://www.sczwfw.gov.cn/col/col15238/index.html?areaCode=510000000000"
          break;
        case 6:
          href = "http://www.sczwfw.gov.cn/col/col15234/index.html?areaCode=510000000000"
          break;
        case 7:
          href = "http://www.sczwfw.gov.cn/col/col15233/index.html?areaCode=510000000000"
          break;
        case 8:
          href = "http://zxbl.sczwfw.gov.cn/themes/themeService/newMainDh?areaCode=510000000000"
          break;
        case 9:
          href = "http://qjd.sczwfw.gov.cn/col/col47864/index.html"
          break;
        default:
          break;
      }
     window.open(href,"_blank");
      // sessionStorage.setItem("titleType", indexkey, 5 * 60 * 1000);
    },
    /**
     * @description: 前往尝鲜版本
     */
    toEarly() {
      const { href } = this.$router.resolve({
        path: "/home_early",
      });
      window.open(href, "_blank");
    },
    /**
     * @description: 显示二维码弹窗
     */
    // showQrPopup() {
    //   // console.log('showQrPopup')
    //   this.$refs["loginQrcode"].startPolling();
    // },
    /**
     * @description: 隐藏二维码弹窗
     */
    hideQrPopup() {
      // console.log('hideQrPopup')
      this.$refs["loginQrcode"].stopPolling();
    },
    /**
     * @description: 前往首页
     */
    toHome() {
      // sessionStorage.setItem("titleType", 1, 5 * 60 * 1000);
      this.$router.push({ name: "homePage"});
    },
    /**
     * @description: 前往登录
     */
    toLogin(backurl) {
      this.$router.push({ path: "/login" });
    },
    /**
     * @description: 前往市登录
     */
    toCityLogin(backurl) {
      this.$router.push({ path: "/slogin" });
    },
    /**
     * @description: 前往注册
     */
    toRegister() {
      // this.$router.push({
      //   path: "/register",
      // });
      let href = "https://rzsc.sczwfw.gov.cn/portal/register_list.jsp?registerType=2";
      window.open(href, "_blank");
    },
    /**
     * @description: 前往工作台
     */
    toWork() {
      this.$router.push({ path: "/workplace/index" });
    },
    gotoItem(el) {
      // console.log("el:", el);
      if (el == "/logout") {
        this.$confirm("是否退出登录？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$store.dispatch("logout", {}).then(() => {
              this.$message.success("登出成功");
              this.$router.push("/home");
            });
          })
          .catch(() => {});
      } else {
        this.$router.push({ path: el });
      }
    },
    getLength(index) {
      if (index === 0) {
        return this.tongzhiTotal;
      } else if (index === 1) {
        return this.gonggaoTotal;
      } else {
        return this.dingyueTotal;
      }
    },
    /* 改变通知栏 */
    changemsNav(index) {
      this.msTitleCurrentNav = index;

      // let path = this.msTitleList[index].path;
      // this.$router.replace({ path: path });
    },
    // 前往我的信息中心
    gotoMyMessage() {
      this.$router.push({ path: "/workplace/message" });
    },
    /**
     * @description: 查询消息
     * @param {*} type 消息类型
     * @return {Promise}
     */
    getMessageData(type) {
      return new Promise((resolve, reject) => {
        let url = "/dev-api/message/list";
        let params = {
          type,
          pageNum: 1,
          pageSize: 3,
        };
        this.$httpApi
          .get(url, params)
          .then((res) => {
            // console.log('getMessageData:', res)
            resolve(res);
          })
          .catch((err) => {
            console.log("getMessageData error:".err);
            reject(err);
          });
      });
    },
    toZwfw() {
      window.open("http://www.sczwfw.gov.cn/");
    },
    // 监听返回按钮的处理函数
    // onCloseModal() {
    //   // 获取当前页面的url地址
    //   sessionStorage.setItem(
    //     "titleType",
    //     sessionStorage.getItem("titleTypeBack")
    //   );
    //   this.$router.go(0);
    // },
    showUserManual(){
      window.open("./用户指南.pdf","_blank")
    },
  },
  created() {
    // if (!sessionStorage.getItem("titleType")) {
    //   sessionStorage.setItem("titleType", this.titleType);
    // }
    if (this.userInfo && this.userInfo.enterpriseList) {
      if (this.userInfo.enterpriseList.length > 0) {
        this.showUserName = this.userInfo.enterpriseList[0].enterprise;
      } else {
        this.showUserName = this.userInfo.nickName;
      }
    }
  },
  // destroyed() {
  //   window.removeEventListener("popstate", this.onCloseModal, false);
  // },
  mounted() {
    // 监听浏览器的返回按钮
    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener("popstate", this.onCloseModal, false);
    // }

    // let titleType = sessionStorage.getItem("titleType");
    // if (titleType) {
    //   this.titleType = Number(titleType);
    // }
    // if (window.location.href.indexOf("policyMatchReport") > -1) {
    //   this.titleType = "3";
    // } else {
    // }
    this.getMessageData(1).then((res) => {
      if (res.code == 200) {
        this.tongzhiList = res.rows || [];
        this.tongzhiTotal = res.total || 0;
      }
    });
    this.getMessageData(2).then((res) => {
      if (res.code == 200) {
        this.gonggaoList = res.rows || [];
        this.gonggaoTotal = res.total || 0;
      }
    });
    this.getMessageData(3).then((res) => {
      if (res.code == 200) {
        this.dingyueList = res.rows || [];
        this.dingyueTotal = res.total || 0;
      }
    });
  },
  watch: {
    userInfo(newVal) {
      console.log("userInfo:", newVal);
    },
    $route(to, from, next) {
      // this.titleType = sessionStorage.getItem("titleType");
    },
  },
};
</script>

<style lang="scss" scoped>
.loginPopover {
  ::v-deep .el-button {
    border: 1px solid transparent;
  }
  ::v-deep .el-button:hover {
        background-color: #fff;
  }
}
.header {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height: 82px;
  cursor: pointer;

  * {
    line-height: 1;
  }
}
.header_right {
  display: flex;
  align-items: center;
  .divider {
    width: 2px;
    height: 16px;
    background-color: #ccc;
    margin-right: 20px;
  }
  .header_right_txt {
    margin-right: 10px;
    color: rgb(102, 102, 102);
  }
}
.header-inner {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-logo {
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
}
.notLogin {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 18px;

  .notLogin-login,
  .notLogin-register {
    line-height: 36px;

    .tj-icon {
      margin-right: 4px;
    }

    &:hover {
      color: $col-theme;
    }
  }
}
.login-register1 {
  height: 30px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.1);
  color: #333;
  padding: 0 20px;
  .login-icon,
  .register-cion {
    i {
      margin-right: 5px;
    }
    img {
      margin-right: 8px;
      width: 14px;
      height: 14px;
      display: block;
    }
    &:hover {
      font-weight: bold;
    }
  }
}
.loginInfo {
  width: 100%;
  padding: 0 15px;
  .login-cell {
    width: 100%;
    padding: 8px 0;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    font-weight: 600;
    .cell-icon {
      width: 30px;
    }
    .login-name {
      flex: 1;
    }
  }
  .login-cell:hover {
    color: $col-theme;
  }
  .login-cell:last-child {
    border-bottom: none;
  }
}
.nav-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  .nav-messgaeTitle {
    font-size: 12px;
    font-weight: 500;
  }
  .message-titleList {
    padding: 8px 10px;
    font-size: 12px;
    font-weight: 800;

    &.message-titleList--active {
      color: $col-theme;
    }
  }
}
.messageInfo {
  //xingliangxiede
  width: 100%;

  .message-list {
    position: relative;
    width: 100%;
    min-height: 160px;
  }
  .message-cell {
    width: 100%;
    padding: 8px 0;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;

    .message-name {
      font-size: 10px;
      flex: 1;
    }
    .message-time {
      text-align: right;
      font-size: 8px;
      flex: 1;
    }
  }
  .message-showMore {
    width: 100%;
    text-align: center;
    font-size: 10px;
    margin-top: 20px;
  }
  .message-cell:hover {
    color: $col-theme;
  }
  .message-showMore:hover {
    color: $col-theme;
  }
  .message-cell:last-child {
    border-bottom: none;
  }
}
.home-container {
  position: relative;
}
.home-title {
  height: 3.125rem;
  // width: 100%;
  background-color: #fff;
  .home-title_1 {
    height: 100%;
    // margin-left: 10%;
    display: flex;
    justify-content: space-evenly;
    .home-title_2 {
      height: 100%;
      // width: 8%;
      // float: left;
      // text-align: left;
      font-size: 18px;
      padding: 15px 0;
      color: #333;
      cursor: pointer;
      font-weight: bold;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right:0;
      }
    }
    .home-title_2:hover {
      color: #2d63da;
    }
    .home-title_2s {
      height: 100%;
      // width: 8%;
      float: left;
      text-align: left;
      font-size: 18px;
      padding: 15px 35px;
      cursor: pointer;
      color: #2d63da;
      font-weight: bold;
      background-color: #ffffff;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right:0;
      }
    }
  }
}
</style>
